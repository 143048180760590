<template>
  <div class="thumbnail align-items-center justify-content-center mr-16">
    <svg-folder v-if="typeNm === 'FOLDER'" />
    <img v-else src="https://via.placeholder.com/40?text=%20" alt="" />
  </div>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'
export default {
  name: 'IconFolder',
  mixins: [mixinForm],
}
</script>

<style scoped>

</style>
