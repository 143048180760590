const A_SET_GROUP_TITLE = ({ commit }, payload) => {
  commit("M_SET_GROUP_TITLE", payload);
};
const A_ALL_SHOW_GROUP = ({ commit }, payload) => {
  commit("M_ALL_SHOW_GROUP", payload);
};
const A_ALL_HIDE_GROUP = ({ commit }, payload) => {
  commit("M_ALL_HIDE_GROUP", payload);
};
const A_SHOW_GROUP = ({ commit }, payload) => {
  commit("M_SHOW_GROUP", payload);
};
const A_HIDE_GROUP = ({ commit }, payload) => {
  commit("M_HIDE_GROUP", payload);
};
const A_NEW_DELETE_GROUP = ({ commit }, payload) => {
  commit("M_NEW_DELETE_GROUP", payload);
};
const A_DELETE_GROUP = ({ commit }, payload) => {
  commit("M_DELETE_GROUP", payload);
};
const A_SET_GRP_DATA = ({ commit }, payload) => {
  commit("M_SET_GRP_DATA", payload);
};
const A_PUSH_GRP_DATA = ({ commit }, payload) => {
  commit("M_PUSH_GRP_DATA", payload);
};
const A_SORT_ASC_GRP_DATA = ({ commit }, payload) => {
  commit("M_SORT_ASC_GRP_DATA", payload);
};
const A_SORT_DESC_GRP_DATA = ({ commit }, payload) => {
  commit("M_SORT_DESC_GRP_DATA", payload);
};


  //MOD
  const A_SET_MOD = ({ commit }, payload) => {
    commit("M_SET_MOD", payload);
  };
  const A_ADD_MOD = ({ commit }, payload) => {
    commit("M_ADD_MOD", payload);
  };
  const A_NEW_DELETE_MOD = ({ commit }, payload) => {
    commit("M_NEW_DELETE_MOD", payload);
  };
  const A_DELETE_MOD = ({ commit }, payload) => {
    commit("M_DELETE_MOD", payload);
  };


  //CONTENT
  const A_SET_CONTENT = ({ commit }, payload) => {
    commit("M_SET_CONTENT", payload);
  };
  const A_SET_TEXT_CONTENT = ({ commit }, payload) => {
    commit("M_SET_TEXT_CONTENT", payload);
  };
  const A_SET_IMAGE_CONTENT = ({ commit }, payload) => {
    commit("M_SET_IMAGE_CONTENT", payload);
  };


  const A_SET_DATA1_CONTENT = ({ commit }, payload) => {
    commit("M_SET_DATA1_CONTENT", payload);
  };
  const A_SET_DATA2_CONTENT = ({ commit }, payload) => {
    commit("M_SET_DATA2_CONTENT", payload);
  };
  const A_SET_TYPE_CONTENT = ({ commit }, payload) => {
    commit("M_SET_TYPE_CONTENT", payload);
  };
  const A_SET_DESCRIPTION_CONTENT = ({ commit }, payload) => {
    commit("M_SET_DESCRIPTION_CONTENT", payload);
  };
  const A_SET_STARTTIME_CONTENT = ({ commit }, payload) => {
    commit("M_SET_STARTTIME_CONTENT", payload);
  };
  const A_SET_ENDTIME_CONTENT = ({ commit }, payload) => {
    commit("M_SET_ENDTIME_CONTENT", payload);
  };
  const A_SET_LIMITTYPE_CONTENT = ({ commit }, payload) => {
    commit("M_SET_LIMITTYPE_CONTENT", payload);
  };
  const A_SET_ANSWERLIST_CONTENT = ({ commit }, payload) => {
    commit("M_SET_ANSWERLIST_CONTENT", payload);
  };

  const A_SET_MISSIONFILELIST_CONTENT = ({ commit }, payload) => {
    commit("M_SET_MISSIONFILELIST_CONTENT", payload);
  };





export default {
  //GROUP
  A_SET_GROUP_TITLE,
  A_ALL_SHOW_GROUP,
  A_ALL_HIDE_GROUP,
  A_SHOW_GROUP,
  A_HIDE_GROUP,
  A_NEW_DELETE_GROUP,
  A_DELETE_GROUP,
  A_SET_GRP_DATA,
  A_PUSH_GRP_DATA,
  A_SORT_ASC_GRP_DATA,
  A_SORT_DESC_GRP_DATA,
  
  //MOD
  A_SET_MOD,
  A_ADD_MOD,
  A_NEW_DELETE_MOD,
  A_DELETE_MOD,

  //CONTENT
  A_SET_CONTENT,
  A_SET_TEXT_CONTENT,
  A_SET_IMAGE_CONTENT,
  
  //CONTENT QUESTION
  A_SET_DATA1_CONTENT,
  A_SET_DATA2_CONTENT,
  A_SET_TYPE_CONTENT,
  A_SET_DESCRIPTION_CONTENT,
  A_SET_STARTTIME_CONTENT,
  A_SET_ENDTIME_CONTENT,
  A_SET_LIMITTYPE_CONTENT,
  A_SET_ANSWERLIST_CONTENT,
  A_SET_MISSIONFILELIST_CONTENT

  
};



