<template>
  <!-- 영수증 -->
  <div class="info-container">
    <iframe
      :src="url"
      width="100%"
      height="100%"
      frameborder="0"
      v-if="url"
    >
    </iframe>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalReceipt",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      esntlId: "",
      url: "",
    };
  },

  /*** created ***/
  async mounted() {},

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE", "G_MEMBER_CODE"]),
  },

  /*** watch ***/
  watch: {
    G_MODAL_TOGGLE(val) {
      if (val) {
        this.esntlId = this["G_MEMBER_CODE"];
        this.getReceipt();
      } else {
        this.esntlId = "";
        this.url = "";
      }
    },
  },

  /*** methods ***/
  methods: {
    async getReceipt() {
      let url = localStorage.getItem('auth') === 'COM' ? "/partner/popup/receipt" : "/admin/popup/receipt"
      const rs = await this.$axios({
        url: url,
        params: { esntlId: this["G_MEMBER_CODE"] },
      });
      if (rs.result === "SUC") {

        this.url = "https://iniweb.inicis.com/DefaultWebApp/mall/cr/cm/mCmReceipt_head.jsp?noTid=" + rs.resultData.tid + "&noMethod=1";
      } else {
        //console.log("영수증 조회 실패");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  padding: 22px;
}
</style>
