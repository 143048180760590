<template>
  <ul class="menu">
    <nav-all />
    <nav-main v-for="v in navList" :key="v.id" :menu="v" />
  </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import NavAll from '@/components/common/aside-wrapper/NavAll'
import NavMain from '@/components/common/aside-wrapper/NavMain'


export default {
  name: 'NavWrap', 
  data() {
    return {
      navList: []
    }
  },
  components: { NavMain, NavAll },
  computed: {
    ...mapGetters(['G_NAV_ALL'])
  },
  mounted() {
    this.navList = JSON.parse(JSON.stringify(this.G_NAV_ALL)).filter((nav) => !nav.id.includes("0"))
  },
}
</script>

<style scoped lang="scss">

</style>
