<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19 10L13 16.0002L19 22" stroke="#828699" stroke-linecap="round"></path>
    <path d="M0 0L0 -1L-1 -1L-1 0L0 0ZM32 0L33 0V-1L32 -1V0ZM32 32V33H33V32H32ZM0 32H-1L-1 33H0L0 32ZM0 1L32 1V-1L0 -1L0 1ZM31 0L31 32H33L33 0L31 0ZM32 31L0 31L0 33L32 33V31ZM1 32L1 0L-1 0L-1 32H1Z" fill="#828699"></path>
  </svg>
</template>

<script>
export default {
  name: 'SvgArrowLeft',

  /*** props ***/
  props: {
    width: {
      type: Number,
      default: 32
    },
    height: {
      type: Number,
      default: 32
    },
    viewBox: {
      type: String,
      default: '0 0 32 32'
    },
    fill: {
      type: String,
      default: 'none'
    },
  },
}
</script>

<style scoped>

</style>
