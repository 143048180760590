<template>
  <svg :width="width" :height="height" :viewBox="viewBox" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13 22L19 15.9998L13 10" stroke="#828699" stroke-linecap="round"></path>
    <path d="M0 0L0 -1H-1V0L0 0ZM32 0L33 0V-1H32V0ZM32 32V33H33V32H32ZM0 32H-1V33H0L0 32ZM0 1H32V-1H0L0 1ZM31 0V32H33V0L31 0ZM32 31H0L0 33H32V31ZM1 32V0L-1 0V32H1Z" fill="#828699"></path>
  </svg>
</template>

<script>
export default {
  name: 'SvgArrowRight',

  /*** props ***/
  props: {
    width: {
      type: Number,
      default: 32
    },
    height: {
      type: Number,
      default: 32
    },
    viewBox: {
      type: String,
      default: '0 0 32 32'
    },
    fill: {
      type: String,
      default: 'none'
    },
  },
}
</script>

<style scoped>

</style>
