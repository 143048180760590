import Vue from "vue";

Vue.filter("taskCntType", (val) => {
  switch (val) {
    case "FOLDER":
      return "폴더";
    case "STUDY":
      return "학습형";
    case "EXE":
      return "실행형";
    default:
      return "";
  }
});

Vue.filter("taskCntTypeBtn", (val) => {
  switch (val) {
    case "FOLDER":
      return "이름변경";
    default:
      return "수정";
  }
});

Vue.filter("courseFeedType", (val) => {
  switch (val) {
    case "QnA":
      return "Q&A";
    case "COM":
      return "코멘트";
    default:
      return "";
  }
});

Vue.filter("chgWidCls", (val) => {
  switch (val) {
    case "T":
      return "tiny";
    case "S":
      return "small";
    case "M":
      return "medium";
    case "L":
      return "large";
    case "F":
      return "full";
    default:
      return "";
  }
});
