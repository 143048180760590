<template>
  <div :class="cls"><slot /></div>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'LabelBox',
  mixins: [mixinForm],

  /*** computed ***/
  computed: {
    cls() {
      let cls = ''
      cls += 'content-form-title '
      cls += this.wpCls
      return cls
    },
  },
}
</script>

<style scoped lang="scss">

</style>
