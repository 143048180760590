export default {
  COURSE_NAME: '',
  TASK_NAME: '',
  TASK_TITLE: '',
  TASK_ID: '',
  CUSTOMER_TASK_ID: '',
  COURSE_ID: '',
  TASK_DAY: '',
  TASK_INDEX: '',
  FEED_TYPE: '',
  USER_NAME: '',
}
