<template>
  <div>
    <p
      style="font-size: 14px; font-weight: 500; text-align: center"
      v-if="data.dataList"
    >
      {{ data.dataList.length > 0 ? convertTime(data.dataList[0].data) : "" }}
    </p>
  </div>
</template>

<script>
export default {
  name: "MissionTI",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  /*** data ***/
  data() {
    return {};
  },

  /*** created ***/
  created() {},

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    convertTime(time) {
      let parseTime = parseInt(time)
      const min = Math.floor(parseTime / 6000);
      const sec = Math.floor((parseTime - (min * 6000)) / 100);
      return min === 0 ? sec + '초' : sec === 0 ? min + '분 ' : min + '분 ' + sec + '초' 
    },
  },
};
</script>

<style scoped lang="scss"></style>
