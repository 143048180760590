<template>
  <div :class="cls">
    <input type="checkbox" :value="value" :class="cls2" :id="labelId" :checked="value" @input="handleChange">
    <label :for="labelId" :class="cls3">
      <slot/>
    </label>
  </div>
</template>


<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'InputCheck',
  mixins: [mixinForm],
  props: ['value'],
  data() {
    return {
      labelId: '',
    }
  },
  created() {
    this.labelId = this.$nnid()
  },

  /*** computed ***/
  computed: {
    cls() {
      let cls = ''
      cls += 'd-flex align-items-center '
      cls += this.wpCls || ''
      return cls
    },
    cls2() {
      let cls = ''
      cls += this.wpCls2 || ''
      return cls
    },
    cls3() {
      let cls = ''
      cls += this.wpCls3 || ''
      return cls
    },
  },
  methods: {
    handleChange(e) {
      if(this.valNm !== '') {
        this.$emit('input', e.target.checked ? this.valNm : '')
      }
      else {
        this.$emit('input', e.target.checked)
      }
    }
  }
}
</script>

<style scoped lang="scss">
label {
  user-select: none;
}
</style>
