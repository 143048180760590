<template>
  <div class="w-100">
    <div class="reject-header-wrap flex-space-between flex-align-center">
      <span>거절 사유</span>
      <img
        src="@/assets/image/close.svg"
        style="cursor: pointer"
        @click="closeModal"
      />
    </div>
    <div class="py-16 w-100">
      <div
        class="reject-content-wrap"
        style="align-items: flex-start; padding: 16px"
      >
        <span style="color: #828699; font-weight: 500; min-width: 150px"
          >거절 사유 내용</span
        >
        <text-box :disabled="disabled" wp-cls="w-100" v-model="rejectReason" />
      </div>
    </div>
    <div class="reject-bottom-wrap">
      <button-common
        :size="SIZE_T"
        :clr="CLR_W"
        @click="closeModal"
        v-if="disabled"
        >확인</button-common
      >
      <button-common
        :size="SIZE_T"
        :clr="CLR_M"
        @click="writeRejectReason"
        v-if="!disabled"
        >완료</button-common
      >
      <button-common
        :size="SIZE_T"
        :clr="CLR_W"
        wp-cls="mr-8"
        @click="closeModal"
        v-if="!disabled"
        >취소</button-common
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalRejectReason",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      disabled: true,
      rejectReason: "",
    };
  },

  /*** created ***/
  async mounted() {},

  /*** computed ***/
  computed: {
    ...mapGetters([
      "G_MODAL_TOGGLE",
      "G_REJECT_REASON",
      "G_REJECT_REASON_DISABLED",
    ]),
  },

  /*** watch ***/
  watch: {
    async G_MODAL_TOGGLE(val) {
      // 열림
      if (val) {
        this.disabled = this["G_REJECT_REASON_DISABLED"];
        this.rejectReason = this["G_REJECT_REASON"]
          ? this["G_REJECT_REASON"]
          : "";
      }
    },
  },

  /*** methods ***/
  methods: {
    writeRejectReason() {
      this.$store.dispatch("A_REJECT_REASON", this.rejectReason);
      this.closeModal();
    },
    closeModal() {
      this.$store.dispatch("A_MODAL_TOGGLE", false);
    },
  },
};
</script>

<style scoped lang="scss">
.reject-header-wrap {
  background: #f8f8fb;
  padding: 16px;
}
.reject-content-wrap {
  padding: 16px;
  display: flex;
  align-items: flex-start;
}
.reject-bottom-wrap {
  background: #f8f8fb;
  padding: 16px;
  display: flex;
  flex-direction: row-reverse;
}
</style>
