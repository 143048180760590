

export default {
  M_SET_GROUP_TITLE(state,{id, title} ) {
    // 그룹의 타이틀을 변경합니다.
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      grp.title = title;
    }
  },
  M_ALL_SHOW_GROUP(state ) {
    // 모든 그룹을 보이게 합니다.
    state.GRP_ARR.forEach((grp) => {
      grp.isShowGrp = true;
    });
  },
  M_ALL_HIDE_GROUP(state ) {
    // 모든 그룹을 보이게 합니다.
    state.GRP_ARR.forEach((grp) => {
      grp.isShowGrp = false;
    });
  },
  M_SHOW_GROUP(state, id) {
    // 그룹을 보이게 합니다.
    state.GRP_ARR.forEach((grp) => {
      if (grp.id === id) {
        grp.isShowGrp = true;
      }
    });
  },
  M_HIDE_GROUP(state, id) {
    // 그룹을 보이지 않게 합니다.
    state.GRP_ARR.forEach((grp) => {
      if (grp.id === id) {
        grp.isShowGrp = false;
      }
    });
  },
  M_NEW_DELETE_GROUP(state, id) {
    // 그룹을 삭제합니다.
    state.GRP_ARR = state.GRP_ARR.filter((grp) => grp.id !== id);
  },
  M_DELETE_GROUP(state, id) {
    // 그룹을 삭제합니다.
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if (grps.length > 0) {
      var grp = grps[0];
      grp.status = "DELETE";
      grp.modArr.forEach((mod) => {
        mod.status = "DELETE";
      });
    }
  },
  M_SET_GRP_DATA(state, newData) {
    state.GRP_ARR = newData;
  },
  M_PUSH_GRP_DATA(state, grp) {
    state.GRP_ARR.push(grp);
  },
  M_SORT_ASC_GRP_DATA(state) {
    state.GRP_ARR = state.GRP_ARR.sort(function (a, b) {
      return parseInt(a.numberOrder) - parseInt(b.numberOrder);
    });
  },
  M_SORT_DESC_GRP_DATA(state) {
    state.GRP_ARR = state.GRP_ARR.sort(function (a, b) {
      return parseInt(b.numberOrder) - parseInt(a.numberOrder);
    });
  },

  //MOD
  M_SET_MOD(state, {id, modArr}) {
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      grp.modArr = modArr;
    }
  },
  M_ADD_MOD(state, { id, mod }) {
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      grp.modArr.push(mod);
    }
  },
  M_NEW_DELETE_MOD(state,{id ,id2} ) {
    // 그룹을 삭제합니다.
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      grp.modArr = grp.modArr.filter((mod) => mod.id !== id2);
    }
  },
  M_DELETE_MOD(state,{id ,id2}) {
    // 그룹을 삭제합니다.
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      const mods = grp.modArr.filter((item) => item.id === id2);
      if (mods.length > 0) {
        var mod = mods[0];
        mod.status = "DELETE";
      }
    }
  },

  //CONTENT
  M_SET_CONTENT(state, {id ,id2, content}) {
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      const mods = grp.modArr.filter((item) => item.id === id2);
      if(mods.length > 0) {
        var mod = mods[0];
        mod.content = { ...content };
      }
    }
  },
  M_SET_TEXT_CONTENT(state, {id ,id2, text}) {
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      const mods = grp.modArr.filter((item) => item.id === id2);
      if(mods.length > 0) {
        var mod = mods[0];
        mod.content.type = "TE";
        mod.content.data1 = text;
      }
    }
  },
  M_SET_IMAGE_CONTENT(state, {id ,id2, imageList}) {
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      const mods = grp.modArr.filter((item) => item.id === id2);
      if(mods.length > 0) {
        var mod = mods[0];
        mod.content.imageList = []
        imageList.forEach((item) => {
          var image = {
            ... item
          }
          mod.content.imageList.push(image);
        });
        mod.content.type = "IM";
      }
    }
  },



//content 수정


  //data1 수정
  M_SET_DATA1_CONTENT(state, {id ,id2, data1}) {
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      const mods = grp.modArr.filter((item) => item.id === id2);
      if(mods.length > 0) {
        var mod = mods[0];
        mod.content.data1 = data1;
      }
    }
  },
  //data2 수정
  M_SET_DATA2_CONTENT(state, {id ,id2, data2}) {
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      const mods = grp.modArr.filter((item) => item.id === id2);
      if(mods.length > 0) {
        var mod = mods[0];
        mod.content.data2 = data2;
      }
    }
  },
  //type 수정
  M_SET_TYPE_CONTENT(state, {id ,id2, type}) {
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      const mods = grp.modArr.filter((item) => item.id === id2);
      if(mods.length > 0) {
        var mod = mods[0];
        mod.content.type = type;
      }
    }
  },
  //description 수정
  M_SET_DESCRIPTION_CONTENT(state, {id ,id2, description}) {
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      const mods = grp.modArr.filter((item) => item.id === id2);
      if(mods.length > 0) {
        var mod = mods[0];
        mod.content.description = description;
      }
    }
  },
  //startTime 수정
  M_SET_STARTTIME_CONTENT(state, {id ,id2, startTime}) {
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      const mods = grp.modArr.filter((item) => item.id === id2);
      if(mods.length > 0) {
        var mod = mods[0];
        mod.content.startTime = startTime;
      }
    }
  },
  //endTime 수정
  M_SET_ENDTIME_CONTENT(state, {id ,id2, endTime}) {
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      const mods = grp.modArr.filter((item) => item.id === id2);
      if(mods.length > 0) {
        var mod = mods[0];
        mod.content.endTime = endTime;
      }
    }
  },
  //limitType 수정
  M_SET_LIMITTYPE_CONTENT(state, {id ,id2, limitType}) {
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      const mods = grp.modArr.filter((item) => item.id === id2);
      if(mods.length > 0) {
        var mod = mods[0];
        mod.content.limitType = limitType;
      }
    }
  },
  //answerList 수정
  M_SET_ANSWERLIST_CONTENT(state, {id ,id2, answerList}) {
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      const mods = grp.modArr.filter((item) => item.id === id2);
      if(mods.length > 0) {
        var mod = mods[0];
        mod.content.answerList = answerList;
      }
    }
  },


  //missionFileList 수정
  M_SET_MISSIONFILELIST_CONTENT(state, {id ,id2, missionFileList}) {
    const grps = state.GRP_ARR.filter((item) => item.id === id);
    if(grps.length > 0) {
      var grp = grps[0];
      const mods = grp.modArr.filter((item) => item.id === id2);
      if(mods.length > 0) {
        var mod = mods[0];
        mod.content.missionFileList = missionFileList;
      }
    }
  },


}
