<template>
  <div style="height: 100%; overflow: auto">
    <table class="search-table">
      <thead>
        <colgroup>
          <col v-for="(col, colIdx) in tblCol" :key="colIdx" :width="col" />
        </colgroup>
      </thead>
      <tr>
        <th v-for="(fld, fldIdx) in tblFld" :key="fldIdx">{{ fld }}</th>
      </tr>
      <tr v-for="(row, rowIdx) in tblRow" :key="rowIdx">
        <td>{{ row.paymentDate }}</td>
        <td>{{ row.paymentEsntlId }}</td>
        <td>{{ row.goodname }}</td>
        <td>{{ getType(row.type) }}</td>
        <td>{{ row.currentNumber }}/{{ row.maxNumber }}개월</td>
        <td>{{ row.customerName }}</td>
        <td>{{ formatPrice(row.calPrice) }}</td>
        <td v-if="isAdmin">{{ row.type === 'N' ? formatPrice(row.price) : '-' }}</td>
        <td v-if="isAdmin">{{ row.type === 'P' ? formatPrice(row.price) : '-' }}</td>
        <td v-if="isAdmin">{{ formatPrice(row.revenue) }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalPaymentCalculate",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      esntlId: "",
      tblCol: ["20%", "10%", "25%", "8%", "8%", "8%", "10%"],
      tblFld: [
        "결제일시",
        "결제번호",
        "결제항목",
        "유형",
        "회차",
        "결제자",
        "정산금액",
      ],
      tblRow: [],
      isAdmin: false,
    };
  },

  /*** created ***/
  async mounted() {
    this.isAdmin = localStorage.getItem("auth") !== "COM";
  },

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE", "G_MEMBER_CODE"]),
  },

  /*** watch ***/
  watch: {
    G_MODAL_TOGGLE(val) {
      if (val) {
        this.esntlId = this["G_MEMBER_CODE"];
        this.tblFld =
          localStorage.getItem("auth") === "COM"
            ? [
                "결제일시",
                "결제번호",
                "결제항목",
                "유형",
                "회차",
                "결제자",
                "정산금액",
              ]
            : [
                "결제일시",
                "결제번호",
                "결제항목",
                "유형",
                "회차",
                "결제자",
                "정산금액",
                "자동결제",
                "위약금",
                "수수료",
              ];
        this.tblCol =
          localStorage.getItem("auth") === "COM"
            ? ["20%", "10%", "25%", "8%", "8%", "8%", "10%"]
            : [
                "20%",
                "10%",
                "25%",
                "8%",
                "8%",
                "8%",
                "10%",
                "10%",
                "10%",
                "10%",
              ];
        this.getPaymentCalculate();
      } else {
        this.esntlId = "";
        this.tblRow = [];
      }
    },
  },

  /*** methods ***/
  methods: {
    async getPaymentCalculate() {
      let url =
        localStorage.getItem("auth") === "COM"
          ? "/partner/calculate/payment"
          : "/admin/calculate/payment";
      const rs = await this.$axios({
        url: url,
        params: { esntlId: this["G_MEMBER_CODE"] },
      });
      if (rs.result === "SUC") {
        this.tblRow = rs.resultList;
      } else {
        //console.log("정산 조회 실패");
      }
    },

    getType(type) {
      switch (type) {
        case "N":
          return "자동결제";
        case "P":
          return "위약금";
        default:
          return "-";
      }
    },

    formatPrice(price) {
      return price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
    },

    formatPhone(num) {
      if (!num) {
        return "";
      }
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  padding: 22px;
}
.info-header-wrap {
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-sub-category {
  color: #babdcc;
  font-size: 14px;
  font-weight: 700;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 8px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  min-width: 200px;
}
.pay-confirm {
  color: #2ec8b5;
}
.pay-ing {
  color: #c4c4c4;
}
.pay-cancel {
  color: #fd765f;
}
.div-search-null {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--gray20);
}
</style>
