<template>
  <div class="aside">
    <!-- <avatar /> -->
    <nav-wrap />
  </div>
</template>

<script>
import NavWrap from '@/components/common/aside-wrapper/NavWrap'

export default {
  name: 'AsideWrapper',
  components: {  NavWrap }
}
</script>

<style scoped lang="scss">
  .aside {
    flex-shrink: 0;
  }
</style>
