const A_PAGE = ({ commit }, payload) => {
  commit('M_PAGE', payload)
}
const A_ORDER = ({ commit }, payload) => {
  commit('M_ORDER', payload)
}
const A_LSTCNT = ({ commit }, payload) => {
  commit('M_LSTCNT', payload)
}
const A_PGCNT = ({ commit }, payload) => {
  commit('M_PGCNT', payload)
}
const A_TOTCNT = ({ commit }, payload) => {
  commit('M_TOTCNT', payload)
}
const A_PAGER_RESET = ({ commit }) => {
  commit('M_PAGER_RESET')
}

export default { A_PAGE, A_ORDER, A_LSTCNT, A_PGCNT, A_TOTCNT, A_PAGER_RESET }
