

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

export const getComponentName = file => {
  let name = ''
  let path = ''
  let files = file.substr(1).split('/')
  for (let i in files) {
    path += files[i] + '/'
    name += capitalize(files[i])
    if (i === files.length - 1) name = path + name + '.vue'
  }
  return name
}

export const convertLiteral = _obj => JSON.parse(JSON.stringify(_obj))

export const getParents = (_obj, _value, _depth, _key = 'id') => {
  _obj = convertLiteral(_obj)
  const keys = _value.split('-')
  let value = ''
  for (let i = 0; i <= _depth; i++) {
    if (keys[i]) value += keys[i] + '-'
  }
  value = value.slice(0, -1)
  return getFindNestedObj(_obj, value, _key)
}

export const getFindNestedObj = (_obj, _value, _key = 'id') => {
  _obj = convertLiteral(_obj)
  if (_obj[_key] === _value) return _obj
  let result, p
  for (p in _obj) {
    if (typeof _obj[p] === 'object') {
      result = getFindNestedObj(_obj[p], _value, _key)
      if (result) {
        return result
      }
    }
  }
  return result
}

export const makeRoutes = (_obj, arr) => {
  _obj = convertLiteral(_obj)
  if(_obj.length) {
    for (let v of _obj) makeRoutes(v, arr)
  }
  if (_obj.sub && _obj.sub.length) {
    for (let v of _obj.sub) makeRoutes(v, arr)
  }
  if (_obj.name) {
    arr.push({
      meta: { id: _obj.id },
      path: _obj.path,
      // props: { nId: _obj.id },
      component: () => import('@/pages/' + _obj.path.split('/')[1] + '/' + _obj.name + '.vue'),
      name: _obj.name
    })
  }
  if (_obj.redirect) {
    arr.push({
      path: _obj.path,
      redirect: _obj.redirect
    })
  }
  return arr
}


export const makeLog = (log, type) => {
  const headCls = `color: #fff; font-style: italic; background-color: ${type === 'err' ? 'darkRed' : 'darkOrange'}; padding: 2px 8px;`
  const headSt = '%c==== LOG.ST ===='
  const headEd = '%c==== LOG.ED ===='
  console.warn(headSt, headCls)
  if(type === 'err'){
    console.log('%c == err ', 'color: #7A0209')
  }
  else {
    console.log('%c == log ', 'color: #437A05')
  }
  if(Array.isArray(log)) {
    for(const v of log) {
      console.log('%c == log ', 'color: #437A05')
      console.log(v)
    }
  }
  else {
    //console.log(log)
  }
  console.warn(headEd, headCls)
}


export const readFile = obj => {
  if (obj.files && obj.files[0]) {
    const reader = new FileReader();
    reader.readAsDataURL(obj.files[0]);
  }
}
