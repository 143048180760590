<template>
  <div class="div-app" @scroll="handleScroll">
    <div class="div-app-title">{{ taskName }}</div>
    <div class="div-app-content">
      <p class="div-app-course-name">{{ courseName }}</p>
    </div>
    <!-- 미션 리스트 -->
    <div
      class="div-app-mission"
      v-for="(content, contentIndex) in contentList"
      :key="contentIndex"
    >
      <div class="div-app-task-wrap">
        <p class="div-app-task-title">{{ content.title }}</p>
      </div>
      <div
        class="div-app-mission-content"
        v-for="(mission, missionIndex) in getMissionList(content.contentList)"
        :key="missionIndex"
      >
        <p class="div-app-mission-title">미션</p>
        <div
          class="div-app-mission-description"
          v-html="mission.description"
        ></div>
        <!-- 미션 완료 전 -->
        <div class="div-app-mission-prev" v-if="mission.status !== 'FIN'">
          <div>
            <div class="div-app-mission-prev-wrap">
              <img :src="getMissionPrevImage(mission)" />
              <p class="div-app-mission-prev-title">
                {{ getMissionPrevTitle(mission) }}
              </p>
            </div>
            <div class="div-app-mission-prev-btn">
              {{ getMissionPrevBtn(mission) }}
            </div>
          </div>
        </div>
        <!-- 미션 완료 후 -->
        <div class="div-app-mission-fin" v-else>
          <div>
            <!-- 상단 타이틀 -->
            <div class="div-app-mission-fin-title-wrap">
              <div class="flex-align-center">
                <img :src="getMissionFinImage(mission)" />
                <p class="div-app-mission-fin-title">미션을 완료하였습니다.</p>
              </div>
              <p class="div-app-mission-fin-date">
                {{ mission.fDate }} {{ mission.fTime }}
              </p>
            </div>
            <!-- 가운데 컨텐츠 -->
            <div class="div-app-mission-fin-content-wrap">
              <!-- 글쓰기 -->
              <MissionTE :data="mission" v-if="mission.type === 'M_TE'" />
              <MissionPR :data="mission" v-if="mission.type === 'M_PR'" />
              <MissionCH :data="mission" v-if="mission.type === 'M_CH'" />
              <MissionDI :data="mission" v-if="mission.type === 'M_DI'" />
              <MissionIM :data="mission" v-if="mission.type === 'M_IM'" />
              <MissionTI :data="mission" v-if="mission.type === 'M_TI'" />
              <MissionMO :data="mission" v-if="mission.type === 'M_MO'" />
            </div>
            <div class="div-app-mission-fin-btn">
              {{ getMissionFinBtn(mission) }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="div-app-mission-divide"
        v-if="getMissionList(content.contentList).length > 0"
      ></div>
    </div>
    <div ref="div-end" />
  </div>
</template>

<script>
import mixinForm from "@/mixins/mixinForm";
import MissionTE from "@/components/ui/preview/MissionTE";
import MissionPR from "@/components/ui/preview/MissionPR";
import MissionCH from "@/components/ui/preview/MissionCH";
import MissionDI from "@/components/ui/preview/MissionDI";
import MissionIM from "@/components/ui/preview/MissionIM";
import MissionTI from "@/components/ui/preview/MissionTI";
import MissionMO from "@/components/ui/preview/MissionMO";
import { mapGetters } from "vuex";

export default {
  name: "ModalPreviewDetail",
  mixins: [mixinForm],
  components: {
    MissionTE,
    MissionPR,
    MissionCH,
    MissionDI,
    MissionIM,
    MissionTI,
    MissionMO,
  },
  data() {
    return {
      courseName: "",
      taskName: "",
      taskTitle: "",
      contentList: [],
      params: {
        order: "DESC",
        taskEsntlId: "",
        customerTaskEsntlId: "",
        page: 1,
        numOfData: 4,
      },
      isEnd: true,
    };
  },
  async created() {
    this.ntDt = this._.cloneDeep(this.$data);
  },
  computed: {
    ...mapGetters([
      "G_MODAL_TOGGLE",
      "G_MODAL_INFO",
      "G_PREVIEW_TASK_ID",
      "G_PREVIEW_CUSTOMER_TASK_ID",
      "G_PREVIEW_TASK_NAME",
      "G_PREVIEW_TASK_TITLE",
      "G_PREVIEW_COURSE_NAME",
    ]),
  },
  watch: {
    async G_MODAL_TOGGLE(val) {
      if (val) {
        this.courseName = this["G_PREVIEW_COURSE_NAME"];
        this.taskName = this["G_PREVIEW_TASK_NAME"];
        this.taskTitle = this["G_PREVIEW_TASK_TITLE"];
      } else {
        Object.assign(this.$data, this.ntDt);
        this.ntDt = this._.cloneDeep(this.$data);
      }
    },
    async G_PREVIEW_TASK_ID() {
      this.courseName = this["G_PREVIEW_COURSE_NAME"];
      this.taskName = this["G_PREVIEW_TASK_NAME"];
      this.taskTitle = this["G_PREVIEW_TASK_TITLE"];
      this.setInit();
      await this.getData();
    },
  },
  methods: {
    // 데이터 가져오기 공통모듈
    async getData() {
      this.params.taskEsntlId = this["G_PREVIEW_TASK_ID"];
      this.params.customerTaskEsntlId = this["G_PREVIEW_CUSTOMER_TASK_ID"];
      const rs = await this.$axios({
        url: "/partner/preview/content",
        params: this.params,
      });
      if (rs.result === "SUC") {
        this.params.page = this.params.page + 1;
        this.isEnd = rs.resultList.length < 4;
        rs.resultList.forEach((item) => {
          this.contentList.push(item);
        });
      } else {
        this.isEnd = true;
      }
    },

    setInit() {
      this.params.page = 1;
      this.contentList = [];
      this.isEnd = true;
    },

    handleScroll(e) {
      const isBottom = e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 10
      if(isBottom && !this.isEnd) {
        this.isEnd = true;
        this.getData();
      }
    },

    convertStatus(status) {
      if (status === "FIN") {
        return "완료";
      } else {
        return "미완료";
      }
    },

    getMissionList(contentList) {
      const missionType = [
        "M_PR",
        "M_MO",
        "M_IM",
        "M_TE",
        "M_CH",
        "M_TI",
        "M_DI",
      ];
      return contentList.filter((item) => missionType.includes(item.type));
    },

    getMissionPrevImage(mission) {
      return require(`@/assets/image/prev/${mission.type}.svg`);
    },

    getMissionFinImage(mission) {
      return require(`@/assets/image/fin/${mission.type}.svg`);
    },

    getMissionPrevTitle(mission) {
      switch (mission.type) {
        case "M_TE":
          return "글을 작성해 미션을 완료하세요.";
        case "M_CH":
          return "체크를 통해 미션을 완료하세요.";
        case "M_PR":
          return "문제를 풀고 미션을 완료하세요.";
        case "M_DI":
          return "진단에 체크하고 미션을 완료하세요.";
        case "M_TI":
          return "시간을 측정해 미션을 완료하세요.";
        case "M_IM":
          return "사진을 업로드해 미션을 완료하세요.";
        case "M_MO":
          return "비디오를 업로드해 미션을 완료하세요.";
      }
    },

    getMissionPrevBtn(mission) {
      switch (mission.type) {
        case "M_TE":
          return "글쓰기";
        case "M_CH":
        case "M_DI":
        case "M_TI":
          return "시작하기";
        case "M_PR":
          return "문제풀기";
        case "M_IM":
        case "M_MO":
          return "업로드하기";
      }
    },

    getMissionFinBtn(mission) {
      switch (mission.type) {
        case "M_TE":
          return "수정하기";
        case "M_CH":
          return "추가하기";
        case "M_TI":
          return "다시하기";
        case "M_PR":
        case "M_DI":
          return "해설보기";
        case "M_IM":
        case "M_MO":
          return "다시올리기";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.div-app {
  width: 375px;
  height: 80vh;
  background: var(--white);
  overflow-y: auto;
  &-title {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    color: #828699;
    border-bottom: 1px solid #dddddd;
  }
  &-content {
    padding: 22px 16px 0 16px;
    margin-top: 18px;
  }
  &-course-name {
    font-weight: 500;
    font-size: 14px;
  }
  &-task-wrap {
    width: 100%;
    padding: 16px 16px 0 16px;
  }
  &-task-title {
    width: 100%;
    font-weight: 700;
    font-size: 18px;
    padding-bottom: 16px;
    border-bottom: 1px solid #dddddd;
  }
}
.div-app-mission {
  width: 100%;
  &-content {
    width: 100%;
    padding: 32px 16px;
  }
  &-title {
    font-size: 16px;
    font-weight: 700;
  }
  &-description {
    width: 100%;
    margin-top: 20px;
  }
  &-prev {
    width: 100%;
    height: 168px;
    margin-top: 24px;
    border: 1px solid #dddddd;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-prev-wrap {
    display: flex;
    align-items: center;
  }
  &-prev-title {
    font-size: 14px;
    font-weight: 500;
    color: #fd765f;
    margin-left: 10px;
  }
  &-prev-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #fd765f;
    color: #ffffff;
    font-weight: 700;
    font-size: 12px;
    width: 109px;
    height: 33px;
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
  }
  &-fin {
    width: 100%;
    padding: 16px;
    margin-top: 24px;
    border: 1px solid #dddddd;
    border-radius: 16px;
  }
  &-fin-title-wrap {
    width: 100%;
    padding: 32px 0;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid #dddddd;
  }
  &-fin-title {
    font-size: 14px;
    font-weight: 500;
    color: #888888;
    margin-left: 10px;
  }
  &-fin-date {
    font-size: 12px;
    font-weight: 400;
    color: #888888;
    margin-top: 10px;
    text-align: center;
  }
  &-fin-content-wrap {
    width: 100%;
    padding: 32px 0;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid #dddddd;
  }
  &-fin-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    color: #fd765f;
    background: #ffffff;
    border: 1px solid #dddddd;
    font-weight: 700;
    font-size: 12px;
    width: 109px;
    height: 33px;
    margin-top: 16px;
    margin-left: auto;
    margin-right: auto;
  }
  &-divide {
    width: 100%;
    height: 16px;
    background: #dddddd;
  }
}
.flex-align-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
