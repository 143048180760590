<template>
  <button type="button" :class="cls" @click="handleChangeCommon">
    <slot />
  </button>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'ButtonCommon',

  /*** mixins ***/
  mixins: [mixinForm],

  /*** components ***/
  components: {  },

  /*** props ***/
  props: {

  },

  /*** data ***/
  data() {
    return {}
  },

  /*** created ***/
  created() {

  },

  /*** computed ***/
  computed: {
    cls() {
      let cls = ''
      cls += (this.wpCls || '') + ' '
      cls += this.clr ? 'btn-' + this.clr.toLowerCase() + ' ' : ''
      cls += this.size || ''
      return cls
    },
  },

  /*** methods ***/
  methods: {

  },
}
</script>

<style lang="scss" scoped>

</style>
