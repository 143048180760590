<template>
  <table class="finder">
    <colgroup>
      <col v-if="useCheckbox" class="checkbox-width"/>
      <col v-for="(wid, idx) in tblCol" :key="`col_${idx}`" :width="wid" />
    </colgroup>
    <thead>
      <tr>
        <th v-if="useCheckbox">
          <div class="d-flex align-items-center">
            <input type="checkbox" id="checkBox" v-model="checkedAll" @change="toggleSelectAll">
            <label for="checkBox">
              <span>&nbsp;</span>
            </label>
          </div>
        </th>
        <th v-for="(fld, idx) in tblFld" :key="`field_${idx}`" class="text-center">{{ fld }}</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(row, idx) in tblRow">
        <tr :key="idx">
          <td v-if="useCheckbox">
            <div class="d-flex align-items-center">
              <input type="checkbox" :id="`label_${idx}`" v-model="row.checked" @change="toggleSelectSingle">
              <label :for="`label_${idx}`">
                <span>&nbsp;</span>
              </label>
            </div>
          </td>
          <slot name="row" :row="row"/>
        </tr>
      </template>
      <tr v-if="!tblRow || tblRow.length === 0">
        <td colspan="100" class="text-center">검색 결과가 없습니다.</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'MainTbl',
  props: {
    tblCol: {
      type: Array,
      value: [],
    },
    tblFld: {
      type: Array,
      value: [],
    },
    tblRow: {
      type: Array,
      value: [],
    },
    useCheckbox: {
      type: Boolean,
      value: false,
    }
  },
  data() {
    return {
      checkedAll: false
    }
  },
  created() {

  },
  computed: {

  },
  methods: {
    toggleSelectAll(e) {
      this.tblRow.forEach(v => v.checked = e.target.checked)
    },
    toggleSelectSingle() {
      this.checkedAll = this.tblRow.every(v => v.checked)
      this.$forceUpdate()
    }
  }
}
</script>

<style scoped>
label {
  user-select: none;
}
</style>
