<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.4444 12H8.5V28H31.5V15H20L17.4444 12Z" fill="#FFEAC5" stroke="#FEC053" stroke-width="2"></path>
  </svg>
</template>

<script>
export default {
  name: 'svg-folder',

  /*** props ***/
  props: {
    width: {
      type: Number,
      default: 40
    },
    height: {
      type: Number,
      default: 40
    },
    viewBox: {
      type: String,
      default: '0 0 40 40'
    },
    fill: {
      type: String,
      default: 'none'
    },
  },
}
</script>

<style scoped lang="scss">

</style>
