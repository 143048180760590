<template>
  <div class="upload-img-box d-flex" :value="value">
    <div class="file-box d-flex column justify-content-center align-items-center">
      <icon-media :type-nm="typeNm">{{ name.length ? '' : lblNm }}</icon-media>
      <div v-if="name.length" class="fm-12 pt-4">{{ name }}</div>
      <div class="d-flex justify-content-center align-items-center">
        <button-common v-if="!name.length" :size="SIZE_T" :clr="CLR_W" wp-cls="mt-24" @click="onUpload">{{ tlNm }}</button-common>
        <button-common v-if="name.length" :size="SIZE_T" :clr="CLR_W" wp-cls="mt-24 mr-8" @click="onUpload">수정</button-common>
        <button-common v-if="name.length" :size="SIZE_T" :clr="CLR_S" wp-cls="mt-24" @click="deleteContent">삭제</button-common>
      </div>
    </div>
    <text-upload class="cont-box" v-model="description" wp-cls="upload-img-box-input" :use-counter="true" :holder-nm="holderNm" @input="handleChange(name, $event)" />
  </div>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'UploaderBoxTx',
  components: {  },
  mixins: [mixinForm],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      name: '',
      description: '',
    }
  },
  computed: {
   
  },
  /*** watch ***/
  watch: {
    value: {
      deep: true,
      handler() {
        this.name = this.value.link ? this.value.link : ''
        this.description = this.value.description ? this.value.description : ''
      }
    }
  },
  methods: {
    async onUpload() {
      await this.$openPrompt({
        cbPrmy: this.cbAddContent,
        headTx: '링크 등록',
        bodyTx: '링크 URL',
        holderNm: 'URL 주소를 입력해주세요',
        btnPrmyNm: '등록',
      }, this)
    },
    cbAddContent(val) {
      this.name = val
      this.handleChange(this.name, this.description)
    },
    handleChange(link, description) {
      this.$emit('input', {link: link, description: description});
    },
    deleteContent() {
      this.name = ''
      this.handleChange(null, null)
    }
  }
}
</script>

<style scoped lang="scss">
.file-box, .cont-box {
  max-width: 320px;
}
</style>
