<template>
  <!-- 정산정보 -->
  <div class="info-container" v-if="infoData">
    <!-- 헤더 -->
    <div class="info-header-wrap flex-space-between flex-align-center">
      <span class="header-category" style="padding-left: 22px"
        >결제취소 요청</span
      >
      <img
        src="@/assets/image/close.svg"
        style="cursor: pointer; width: 12px; height: 12px; margin-right: 22px"
        @click="closeModal"
      />
    </div>
    <!-- 결제항목 -->
    <div class="info-content-wrap mt-4">
      <span class="content-category">결제항목</span>
      <span>{{ infoData.goodname }}</span>
    </div>
    <!-- 항목번호 -->
    <div class="info-content-wrap mt-4" v-if="isAdmin">
      <span class="content-category">항목번호</span>
      <span>{{ infoData.serviceEsntlId }}</span>
    </div>
    <!-- 유형 -->
    <div class="info-content-wrap mt-4">
      <span class="content-category">유형</span>
      <span>{{ getType(infoData.type) }}</span>
    </div>
    <!-- 회차 -->
    <div class="info-content-wrap mt-4">
      <span class="content-category">회차</span>
      <span>{{ infoData.currentNumber }}/{{ infoData.maxNumber }}개월</span>
    </div>
    <!-- 결제자 -->
    <div class="info-content-wrap mt-4">
      <span class="content-category">결제자</span>
      <span>{{ infoData.customerName }}</span>
    </div>
    <!-- 멤버코드 -->
    <div class="info-content-wrap mt-4" v-if="isAdmin">
      <span class="content-category">멤버코드</span>
      <span>{{ infoData.customerEsntlId }}</span>
    </div>
    <!-- 결제금액 -->
    <div class="info-content-wrap mt-4">
      <span class="content-category">결제금액</span>
      <span>{{ formatPrice(infoData.price) }}원</span>
    </div>
    <!-- 버튼 -->
    <div
      class="info-header-wrap flex-space-between flex-align-center"
      style="justify-content: flex-end"
    >
      <button-common
        :size="SIZE_T"
        :clr="CLR_W"
        style="margin-right: 16px"
        @click="doReject"
        >거절</button-common
      >
      <button-common
        :size="SIZE_T"
        :clr="CLR_M"
        style="margin-right: 22px"
        @click="doApprove"
        >승인</button-common
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalPaymentCancel",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      infoData: null,
      isAdmin: false,
    };
  },

  /*** created ***/
  async created() {},

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE", "G_PAYMENT_DATA"]),
  },

  /*** watch ***/
  watch: {
    G_MODAL_TOGGLE(val) {
      if (val) {
        this.infoData = this["G_PAYMENT_DATA"];
      } else {
        this.infoData = null;
      }
    },
  },

  mounted() {
    this.isAdmin = localStorage.getItem("auth") === "ADM";
  },

  /*** methods ***/
  methods: {
    async doApprove() {
      const url =
        this.infoData.type === "N"
          ? `/${this.isAdmin ? "admin" : "partner"}/payment/cancel/accept`
          : `/${this.isAdmin ? "admin" : "partner"}/payment/term/cancel/accept`;
      const data = this.isAdmin
        ? {
            esntlId: this.infoData.paymentEsntlId,
            partnerEsntlId: this.infoData.partnerEsntlId,
          }
        : { esntlId: this.infoData.paymentEsntlId };
      const rs = await this.$axios({
        url: url,
        params: data,
      });
      if (rs.result === "SUC") {
        await this.$store.dispatch("A_IS_CONFIRM", true);
        this.closeModal();
      } else {
        //console.log("취소 승인 실패");
      }
    },

    async doReject() {
      const url =
        this.infoData.type === "N"
          ? `/${this.isAdmin ? "admin" : "partner"}/payment/cancel/cancel`
          : `/${this.isAdmin ? "admin" : "partner"}/payment/term/cancel/cancel`;
      const data = this.isAdmin
        ? {
            esntlId: this.infoData.paymentEsntlId,
            partnerEsntlId: this.infoData.partnerEsntlId,
          }
        : { esntlId: this.infoData.paymentEsntlId };
      const rs = await this.$axios({
        url: url,
        params: data,
      });
      if (rs.result === "SUC") {
        await this.$store.dispatch("A_IS_CONFIRM", true);
        this.closeModal();
      } else {
        //console.log("취소 거절 실패");
      }
    },

    getType(type) {
      switch (type) {
        case "N":
          return "자동결제";
        case "P":
          return "위약금";
        default:
          return "자동결제";
      }
    },

    closeModal() {
      this.$store.dispatch("A_MODAL_TOGGLE", false);
    },

    formatPrice(price) {
      return price
        ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        : "";
    },

    formatPhone(num) {
      if (!num) {
        return "";
      }
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
}
.info-header-wrap {
  width: 100%;
  height: 52px;
  background: #f8f8fb;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-sub-category {
  color: #babdcc;
  font-size: 14px;
  font-weight: 700;
}
.header-status-complete {
  color: #2ccdc3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 16px 22px;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  min-width: 200px;
}
.last-login {
  color: #2ec8b5;
}
</style>
