<template>
  <div :class="cls">
    <textarea :value="value" :class="cls2" @input="handleInput" :maxlength="useCounter ? limitLength : 524288" :placeholder="placeholder ? placeholder : holderNm" />
    <div :class="cls3" v-if="useCounter">
      <span :class="counterCls">{{ value.length }}</span>
      <span>/</span>
      <span>{{ limitLength }}</span>
    </div>
  </div>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'TextBox',

  /*** mixins ***/
  mixins: [mixinForm],

  /*** components ***/
  components: {  },

  /*** props ***/
  props: {
    value: {
      type: String,
      default: ''
    },
    limitLength: {
      type: Number,
      default: 100
    },
    placeholder: {
      type: String,
      default: ''
    }
  },

  /*** data ***/
  data() {
    return {
      ntDt: null,
      counterCls: '',
    }
  },

  /*** created ***/
  created() {
    this.ntDt = this._.cloneDeep(this.$data)
  },

  /*** computed ***/
  computed: {
    cls() {
      let cls = ''
      cls += 'input-rel '
      cls += this.wpCls || ''
      return cls
    },
    cls2() {
    let cls = ''
      cls += 'input-detail '
      cls += this.wpCls2 || ''
      return cls
    },
    cls3() {
      let cls = ''
      cls += 'input-count fr-12 f-gray z-3 '
      cls += this.wpCls3 || ''
      return cls
    },
  },

  /*** watch ***/
  watch: {
    value() {
      if(this.useCounter) {
        this.counterCls = this.value.length >= this.limitLength ? 'f-red' : 'f-main'
      }
      if(this.value.length === 0) this.$refs.inputRef ? this.$refs.inputRef.focus() : ''
    }
  },

  /*** methods ***/
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value)
    }
  },
}
</script>

<style scoped lang="scss">

</style>
