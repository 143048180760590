<template>
  <div>
    <div class="d-flex" v-if="data.missionFileList">
      <img :src="getImage()" style="width: 135px; height: 135px" />
      <div class="flex-align-center" style="margin-left: 25px;">
        <div>
          <p style="font-size: 12px; text-align: center">
            {{
              data.missionFileList.length > 0
                ? data.missionFileList[0].oriName
                : ""
            }}
          </p>
          <div class="detail-button">자세히보기</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MissionIM",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  /*** data ***/
  data() {
    return {};
  },

  /*** created ***/
  created() {},

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    getImage() {
      if (this.data.missionFileList.length === 0) {
        return "";
      }
      return (
        process.env.VUE_APP_API_PROXY +
        "/upload/user/" +
        this.data.missionFileList[0].file
      );
    },
  },
};
</script>

<style scoped lang="scss">
.flex-align-center {
  display: flex;
  align-items: center;
}
.detail-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: #fd765f;
  background: #ffffff;
  border: 1px solid #dddddd;
  font-weight: 700;
  font-size: 12px;
  width: 109px;
  height: 33px;
  margin-top: 16px;
  margin-left: auto;
  margin-right: auto;
}
</style>
