<template>
  <div class="div-app">
    <div class="div-app-title">{{ userName }}</div>
    <!-- 코멘트 -->
    <div class="div-comment" v-if="params.feedType === 'COM'">
      <p class="div-comment-title">코멘트</p>
      <template v-if="questionList.length > 0">
        <!-- 코멘트 전 -->
        <div class="div-comment-prev" v-if="!questionList[0].answer">
          <div>
            <p class="div-comment-prev-title">코멘트 작성 전입니다.</p>
            <button
              class="tiny btn-w"
              style="width: 100%"
              @click="openWrite(null, false)"
            >
              코멘트 작성
            </button>
          </div>
        </div>
        <!-- 코멘트 후 -->
        <div class="div-comment-after" v-else>
          <!-- 프로필 부분 -->
          <div class="flex-space-between flex-align-center">
            <div class="flex-align-center">
              <div class="div-app-profile-none"></div>
              <span class="div-app-coach">{{ questionList[0].coachName }}</span>
            </div>
            <div>
              <span
                style="
                  padding: 4px 12px;
                  font-weight: 500;
                  font-size: 12px;
                  background: #fff;
                  border-radius: 8px;
                  border: 1px solid #ddd;
                "
                v-if="questionList[0].answerFile"
                >첨부</span
              >
              <span
                style="
                  padding: 4px 12px;
                  font-weight: 500;
                  font-size: 12px;
                  background: #fff;
                  border-radius: 8px;
                  border: 1px solid #ddd;
                  margin-left: 8px;
                "
                v-if="questionList[0].answerLink"
                >링크</span
              >
            </div>
          </div>
          <!-- 내용 부분 -->
          <div class="div-app-answer">
            {{ questionList[0].answer }}
          </div>
          <!-- 날짜 부분 -->
          <div class="flex-align-center" style="margin-top: 8px">
            <span class="div-app-date">{{ questionList[0].answerDate }}</span>
            <span class="div-app-date-divider">|</span>
            <span
              class="div-app-date-edit"
              @click="openWrite(questionList[0].esntlId, true)"
              >수정</span
            >
          </div>
        </div>
      </template>
    </div>
    <!-- Q&A -->
    <div class="div-qna" v-if="params.feedType === 'QnA'">
      <p class="div-qna-title">Q&A</p>
      <div class="div-qna-wrap">
        <!-- 상단 -->
        <div class="div-qna-top">
          <p class="div-qna-top-title">
            {{ 10 - questionList.length }}회 질문이 가능합니다.
          </p>
          <div class="div-qna-top-tap">
            <div class="div-qna-tap">
              <span
                :class="`div-qna-tap${filter === 'ALL' ? '-on' : '-off'}`"
                @click="setFilter('ALL')"
                >전체</span
              >
              <span class="div-qna-tap-divider">|</span>
              <span
                :class="`div-qna-tap${filter === 'COMPLETE' ? '-on' : '-off'}`"
                @click="setFilter('COMPLETE')"
                >답변완료</span
              >
              <span class="div-qna-tap-divider">|</span>
              <span
                :class="`div-qna-tap${
                  filter === 'UNCOMPLETE' ? '-on' : '-off'
                }`"
                @click="setFilter('UNCOMPLETE')"
                >미답변</span
              >
            </div>
            <span class="div-qna-tap-count"
              >{{ questionList.length }} / 10</span
            >
          </div>
        </div>
        <!-- 하단 -->
        <div class="div-qna-bottom">
          <template v-if="filterQuestionList.length > 0">
            <div
              class="div-qna-bottom-list"
              v-for="(data, idx) in filterQuestionList"
              :key="idx"
            >
              <!-- 상단 -->
              <div class="div-qna-list-item-top">
                <p class="div-qna-list-item-question">{{ data.question }}</p>
                <div class="d-flex" style="margin-top: 10px">
                  <span class="div-qna-list-item-status">{{
                    data.answer ? "답변완료" : "미답변"
                  }}</span>
                  <span class="div-qna-list-item-divider">|</span>
                  <span class="div-qna-list-item-date">{{
                    data.questionDate
                  }}</span>
                </div>
              </div>
              <!-- 하단 -->
              <!-- 답변이 있을 경우 -->
              <div
                :class="`div-qna-list-item-bottom${
                  idx === questionList.length - 1 ? '-last' : ''
                }`"
                v-if="data.answer"
              >
                <div class="div-qna-list-item-bottom-wrap">
                  <!-- 프로필 부분 -->
                  <div class="flex-space-between flex-align-center">
                    <div class="flex-align-center">
                      <div class="div-app-profile-none"></div>
                      <span class="div-app-coach">{{ data.coachName }}</span>
                    </div>
                    <div>
                      <span
                        style="
                          padding: 4px 12px;
                          font-weight: 500;
                          font-size: 12px;
                          background: #fff;
                          border-radius: 8px;
                          border: 1px solid #ddd;
                        "
                        v-if="data.answerFile"
                        >첨부</span
                      >
                      <span
                        style="
                          padding: 4px 12px;
                          font-weight: 500;
                          font-size: 12px;
                          background: #fff;
                          border-radius: 8px;
                          border: 1px solid #ddd;
                          margin-left: 8px;
                        "
                        v-if="data.answerLink"
                        >링크</span
                      >
                    </div>
                  </div>
                  <!-- 내용 부분 -->
                  <div class="div-app-answer">
                    {{ data.answer }}
                  </div>
                  <!-- 날짜 부분 -->
                  <div class="flex-align-center" style="margin-top: 8px">
                    <span class="div-app-date">{{ data.answerDate }}</span>
                    <span class="div-app-date-divider">|</span>
                    <span
                      class="div-app-date-edit"
                      @click="openWrite(data.esntlId, true)"
                      >수정</span
                    >
                  </div>
                </div>
              </div>
              <!-- 답변이 없을 경우 -->
              <div
                :class="`div-qna-list-item-bottom-none${
                  idx === questionList.length - 1 ? '-last' : ''
                }`"
                v-else
              >
                <button
                  class="tiny btn-w"
                  style="width: 143px"
                  @click="openWrite(data.esntlId, false)"
                >
                  답변하기
                </button>
              </div>
            </div>
          </template>

          <div class="div-qna-bottom-none" v-else>질문이 없습니다.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixinForm from "@/mixins/mixinForm";
import { mapGetters } from "vuex";

export default {
  name: "ModalFeedbackUser",
  mixins: [mixinForm],
  data() {
    return {
      userName: "",
      selectedTaskId: "",
      questionList: [],
      filter: "ALL",
      filterQuestionList: [],
      params: {
        order: "DESC",
        esntlId: "",
        feedType: "",
      },
    };
  },
  async created() {
    this.ntDt = this._.cloneDeep(this.$data);
  },
  computed: {
    ...mapGetters([
      "G_MODAL_TOGGLE",
      "G_MODAL_INFO",
      "G_PREVIEW_CUSTOMER_TASK_ID",
      "G_PREVIEW_FEED_TYPE",
      "G_PREVIEW_USER_NAME",
    ]),
  },
  watch: {
    async G_MODAL_TOGGLE(val) {
      // 열림
      if (val) {
        this.userName = this["G_PREVIEW_USER_NAME"];
        await this.getData();
      } else {
        Object.assign(this.$data, this.ntDt);
        this.ntDt = this._.cloneDeep(this.$data);
      }
    },
  },
  methods: {
    // 데이터 가져오기 공통모듈
    async getData() {
      this.params.esntlId = this["G_PREVIEW_CUSTOMER_TASK_ID"];
      this.params.feedType = this["G_PREVIEW_FEED_TYPE"];
      const rs = await this.$axios({
        url: "/admin/feed/detail",
        params: this.params,
      });
      this.questionList = rs.resultList;
      this.filterQuestionList = rs.resultList;
    },

    openWrite(id, isEdit) {
      if (this.params.feedType === "COM") {
        let esntlId =
          this.questionList.length > 0 ? this.questionList[0].esntlId : "";
        this.$router.push({
          path: `/course/feedback/request/add?feedType=${this.params.feedType}&id=${esntlId}&isEdit=${isEdit}`,
          meta: { id: "4-2-1-1" },
        }).catch(() => { });
      } else {
        this.$router.push({
          path: `/course/feedback/request/add?feedType=${this.params.feedType}&id=${id}&isEdit=${isEdit}`,
          meta: { id: "4-2-1-1" },
        }).catch(() => { });
      }
      this.$store.dispatch("A_MODAL_TOGGLE", false);
    },

    setFilter(filter) {
      this.filter = filter;
      if (filter === "ALL") {
        this.filterQuestionList = this.questionList;
      }
      if (filter === "COMPLETE") {
        this.filterQuestionList = this.questionList.filter(
          (item) => item.answer
        );
      }
      if (filter === "UNCOMPLETE") {
        this.filterQuestionList = this.questionList.filter(
          (item) => !item.answer
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.div-app {
  width: 375px;
  height: 80vh;
  background: var(--white);
  overflow-y: auto;
  &-title {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #ddd;
  }
  &-profile-none {
    width: 32px;
    height: 32px;
    background: #dddddd;
    border-radius: 32px;
  }
  &-coach {
    font-size: 14px;
    font-weight: 500;
    margin-left: 8px;
  }
  &-answer {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    margin-top: 16px;
    word-break: break-all;
  }
  &-date {
    font-weight: 400;
    font-size: 12px;
    color: #888888;
  }
  &-date-divider {
    font-weight: 400;
    font-size: 10px;
    color: #dddddd;
    margin: 0 8px;
  }
  &-date-edit {
    font-weight: 400;
    font-size: 12px;
    color: #fd765f;
    cursor: pointer;
  }
}
.div-comment {
  padding: 40px 16px;
  margin-bottom: 55px;
  &-title {
    font-weight: 700;
    font-size: 16px;
  }
  &-prev {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 107px;
    border: 1px solid #dddddd;
    border-radius: 16px;
    margin-top: 24px;
  }
  &-prev-title {
    font-size: 14px;
    font-weight: 500;
    color: #888888;
    margin-bottom: 16px;
  }
  &-after {
    width: 100%;
    padding: 24px 16px;
    border: 1px solid #dddddd;
    border-radius: 16px;
    margin-top: 24px;
  }
}
.div-qna {
  padding: 40px 16px;
  margin-bottom: 55px;
  &-title {
    font-weight: 700;
    font-size: 16px;
  }
  &-wrap {
    width: 100%;
    border: 1px solid #dddddd;
    border-radius: 16px;
    margin-top: 24px;
  }
  &-top {
    padding-bottom: 16px;
    border-bottom: 1px solid #ddd;
  }
  &-top-title {
    color: #888888;
    font-weight: 500;
    font-size: 14px;
    width: 100%;
    text-align: center;
    margin-top: 48px;
  }
  &-top-tap {
    padding: 0 16px;
    margin-top: 33px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &-tap-on {
    color: #fd765f;
    font-size: 12px;
    font-weight: 700;
    cursor: pointer;
  }
  &-tap-off {
    color: #888888;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
  }
  &-tap-divider {
    color: #dddddd;
    font-size: 10px;
    font-weight: 400;
    margin: 0 4px;
  }
  &-tap-count {
    color: #fd765f;
    font-size: 14px;
    font-weight: 500;
  }
  &-bottom-none {
    width: 343px;
    height: 116px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #888888;
    font-size: 14px;
    font-weight: 500;
  }
}
.div-qna-list-item {
  &-top {
    padding: 16px;
  }
  &-question {
    font-weight: 500;
    font-size: 14px;
  }
  &-status {
    font-weight: 500;
    font-size: 12px;
    color: #888888;
  }
  &-divider {
    font-weight: 400;
    font-size: 10px;
    color: #dddddd;
    margin: 0 8px;
  }
  &-date {
    font-weight: 400;
    font-size: 12px;
    color: #888888;
  }
  &-bottom {
    width: 100%;
    padding: 16px;
    background: #fdf4f4;
  }
  &-bottom-last {
    width: 100%;
    padding: 16px;
    background: #fdf4f4;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  &-bottom-none {
    width: 100%;
    height: 71px;
    background: #fdf4f4;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-bottom-none-last {
    width: 100%;
    height: 71px;
    background: #fdf4f4;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
  &-bottom-wrap {
    padding: 8px 0 8px 16px;
    border-left: 1px solid #fd765f;
  }
}

.flex-justify-center {
  display: flex;
  justify-content: center;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
}
</style>
