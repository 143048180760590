import _ from 'lodash'
export default {
  M_FOLDER_ADD (state, folder) {
    if(_.findIndex(state.FOLDER, ['id', folder.id]) === -1) state.FOLDER = [...state.FOLDER, folder]
  },
  M_FOLDER_CUT (state, id) {
    const end = _.findIndex(state.FOLDER, ['id', id]) + 1
    state.FOLDER = [...state.FOLDER].slice(0, end)
  },
  M_FOLDER_ID (state, id) {
    state.ID = id
  },
  M_FOLDER_RESET(state) {
    state.ID = ''
    state.FOLDER = []
  }
}
