<template>
  <div class="p-32 w-100">
    <!--* Task Move Table -->
    <path-content2 @change="getData()"/>
    <div class="body-wp">
      <main-tbl
          :tbl-col="tblCol"
          :tbl-fld="tblFld"
          :tbl-row="tblRow">
        <template v-slot:row="{ row }">
          <td :class="`d-flex align-items-center pointer ${ row.active ? 'active' : ''  } `" @click="onChange(row)" :key="row.esntlId">
            <icon-folder :type-nm="row.type" srcNm=""/>
            {{ row.title }}
          </td>
        </template>
      </main-tbl>
      <modal-pagination @click="onChangePage"/>
    </div>
  </div>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'
import PathContent2 from '@/components/common/content-wrapper/PathContent2'

import { mapGetters } from 'vuex'

export default {
  name: 'ModalTaskMove',
  mixins: [mixinForm],
  components: { PathContent2 },
  data() {
    return {
      ntDt: {},

      delay: 300,
      clicks: 0,
      timer: null,

      selOrd: '', // 리스트 정렬
      selCnt: 1,     // 리스트 보여질 개수
      // list
      params: {
        page: 1,
        numOfData: 10,
        order: '',
        folderEsntlId: '',
        type: 'FOLDER',
      },
      tblCol: ['auto'],
      tblFld: ['이름'],
      tblRow: [],
      selEsntlId: '',
    }
  },
  async created() {
    this.params = { ...this.params, page: this['PAGER2/G_PAGE'], order: this['PAGER2/G_ORDER'], numOfData: 10 }
    this.ntDt = this._.cloneDeep(this.$data)
  },
  computed: {
    ...mapGetters(['G_MODAL_TOGGLE', 'G_MODAL_INFO']),
    ...mapGetters(['FOLDER2/G_FOLDER', 'FOLDER2/G_FOLDER_ID', 'FOLDER2/G_FOLDER_ARR']),
    ...mapGetters(['PAGER2/G_PAGE', 'PAGER2/G_ORDER']),
  },
  watch: {
    async G_MODAL_TOGGLE(val) {
      // 열림
      if(val) {
        await this.getData()
      }
      else {
        Object.assign(this.$data, this.ntDt)
        this.ntDt = this._.cloneDeep(this.$data)
        await this.$store.dispatch('FOLDER2/A_FOLDER_RESET')
        await this.$store.dispatch('PAGER2/A_PAGER_RESET')
      }
    }
  },
  methods: {
    // 데이터 가져오기 공통모듈
    async getData() {
      if(!this['FOLDER2/G_FOLDER_ID']) {
        this.$emit('change', "")
      }
      this.params = { ...this.params, folderEsntlId: this['FOLDER2/G_FOLDER_ID'] }
      const rs = await this.$axios({ url: '/admin/content/task/data', params: this.params })
      this.tblRow = rs.resultList.filter(v => v.type === 'FOLDER')
      await this.$store.dispatch('PAGER2/A_PAGE', rs.page)
      await this.$store.dispatch('PAGER2/A_TOTCNT', rs.totcnt)
    },

    // 페이지 이동
    async onChangePage(value) {
      this.params = { ...this.params, page: value }
      this.pager = { ...this.pager, page: value }
      await this.getData()
    },

    // 폴더 변경
    async onChange(row) {
      this.clicks++
      if(this.clicks === 1) {
        let self = this
        this.selEsntlId = row.esntlId
        this.$emit('change', this.selEsntlId)
        this.tblRow = this.tblRow.map(v => {
          v.active = v.esntlId === self.selEsntlId;
          return v
        })
        this.timer = setTimeout(function() {
          self.clicks = 0
        }, this.delay);
      } else{
        clearTimeout(this.timer);
        // dblclick...
        await this.$store.dispatch('FOLDER2/A_FOLDER_ADD', { id: row.esntlId, name: row.title })
        await this.$store.dispatch('FOLDER2/A_FOLDER_ID', row.esntlId)
        await this.getData()
        this.clicks = 0;
      }
    },
  }
}
</script>

<style scoped lang="scss">
td.active {
  background-color: var(--main70);
  color: var(--white)
}
</style>
