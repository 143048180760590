<template>
  <div class="upload-img-box d-flex" :value="value">
    <div class="file-box d-flex column justify-content-center align-items-center">
      <icon-media :type-nm="typeNm">{{ name.length ? '' : lblNm }}</icon-media>
      <div v-if="name.length" class="fm-12 pt-4">{{ name }}</div>
      <input :accept="accept" type="file" class="d-none" ref="fileRef" @input="handleUpload"/>
      <div class="d-flex justify-content-center align-items-center">
        <button-common v-if="!name.length" :size="SIZE_T" :clr="CLR_W" wp-cls="mt-24" @click="$refs.fileRef.click()">{{ tlNm }}</button-common>
        <button-common v-if="name.length" :size="SIZE_T" :clr="CLR_W" wp-cls="mt-24 mr-8" @click="$refs.fileRef.click()">수정</button-common>
        <button-common v-if="name.length" :size="SIZE_T" :clr="CLR_S" wp-cls="mt-24" @click="deleteFile">삭제</button-common>
      </div>
    </div>
    <text-upload class="cont-box" v-model="description" wp-cls="upload-img-box-input" :use-counter="true" :holder-nm="holderNm" @input="handleChange(file, $event)" />
  </div>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'UploaderBox',
  components: {  },
  mixins: [mixinForm],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    fileName: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      file: null,
      name: '',
      description: '',
    }
  },
  mounted() {
    this.name = this.fileName ? this.fileName : ''
  },

  computed: {
    accept() {
      let acceptValue = ''
      switch(this.typeNm) {
        case 'IMG':
        case 'IMG_P':
          acceptValue = 'image/*'
          break
        case 'VIDEO':
        case 'VIDEO_P':
          acceptValue = 'video/*'
          break
        default:
          break
      }
      return acceptValue
    }
  },
  /*** watch ***/
  watch: {
    value: {
      deep: true,
      handler() {
        this.file = this.value.file ? this.value.file : null
        this.description = this.value.description ? this.value.description : ''
      }
    },
    fileName: {
      handler() {
        this.name = this.fileName ? this.fileName : ''
      }
    }
  },
  methods: {
    handleUpload(e) {
      if (e.target.files[0]) {
        this.file = e.target.files[0]
        this.name = e.target.files[0].name
        this.handleChange(this.file, this.description)
      }
    },
    handleChange(file, description) {
      this.$emit('input', {file: file, description: description});
    },
    deleteFile() {
      this.name = ''
      this.handleChange(null, null)
    }
  }
}
</script>

<style scoped lang="scss">
.file-box, .cont-box {
  max-width: 320px;
}
</style>
