export default {
  MODAL_TOGGLE: false,
  MODAL_INFO: {
    uiType: '', // ALERT, PROMPT, CONFIRM
    cbPrmy: null, // 값바뀔때 콜백
    cbScnd: null, // 닫을때 콜백
    defVal: '', // PROMPT에서 기본값
    size: '', // tiny, small, large
    headCls: '',  // head class
    bodyCls: '',  // body class
    footCls: '',  // footer class
    headTx: '', // head text
    bodyTx: '', // body text
    footTx: '', // foot text
    btnSize: 'tiny',  // btn size tiny, small, medium, large, full
    btnPrmyNm: '확인',  // prmy button text
    btnScndNm: '취소',  // scnd button text
    btnPrmyClr: 'm', // prmy button color w, s, m
    btnScndClr: 'w', // scnd button text w, s, m
    btnPrmyCls: '', // prmy button class
    btnScndCls: '', // scnd button class
    
    useCounter: false,
    limitNm: 0,
    
    data: {},
  }
}
