export default {
  M_NAV_ALL (state, payload) {
    state.ALL = payload
  },
  M_NAV_ID (state, payload) {
    state.ID = payload
  },
  M_NAV_SHOW (state, payload) {
    state.SHOW = payload
  },
  M_NAV_LAST_PATH (state, payload) {
    state.LAST_PATH = payload
  },
}
