<template>
  <div class="div-app">
    <div class="div-app-title">{{ courseName }}</div>
    <!-- 리스트 -->
    <div v-for="(data, dataIndex) in arrData" :key="dataIndex">
      <div class="div-app-list-header">
        <div class="div-app-list-header-title">{{ data.day }}일차</div>
      </div>
      <div
        :class="`div-app-list-content ${
          selectedTaskDay === data.day && selectedTaskIndex === contentIndex
            ? 'selected'
            : ''
        }`"
        v-for="(content, contentIndex) in data.content"
        :key="content.esntlId"
        @click="
          setTaskData(content, data.day, data.content.length, contentIndex)
        "
      >
        <div class="div-app-list-content-main">
          <span class="div-app-list-content-title">{{ content.title }}</span>
          <span class="div-app-list-content-type">{{
            content.feedType | courseFeedType
          }}</span>
        </div>
        <div class="div-app-list-content-sub">
          <span
            :class="`div-app-list-content-status${
              convertStatus(content.status) === '미완료' ? '-inactive' : ''
            }`"
            class="div-app-list-content-status"
            >{{ convertStatus(content.status) }}</span
          >
          <span
            class="div-app-list-content-date"
            v-if="convertStatus(content.status) === '완료'"
            >{{ content.fDate }} {{ content.fTime }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixinForm from "@/mixins/mixinForm";
import { mapGetters } from "vuex";

export default {
  name: "ModalPreview",
  mixins: [mixinForm],
  data() {
    return {
      courseName: "",
      selectedTaskDay: -1,
      selectedTaskIndex: -1,
      arrData: [],
      params: {
        order: "DESC",
        esntlId: "",
      },
    };
  },
  async created() {
    this.ntDt = this._.cloneDeep(this.$data);
  },
  computed: {
    ...mapGetters([
      "G_MODAL_TOGGLE",
      "G_MODAL_INFO",
      "G_PREVIEW_COURSE_ID",
      "G_PREVIEW_TASK_DAY",
      "G_PREVIEW_TASK_INDEX",
      "G_PREVIEW_COURSE_NAME",
    ]),
  },
  watch: {
    async G_MODAL_TOGGLE(val) {
      // 열림
      if (val) {
        this.courseName = this["G_PREVIEW_COURSE_NAME"];
        this.selectedTaskDay = this["G_PREVIEW_TASK_DAY"];
        this.selectedTaskIndex = this["G_PREVIEW_TASK_INDEX"];
        await this.getData();
      } else {
        Object.assign(this.$data, this.ntDt);
        this.ntDt = this._.cloneDeep(this.$data);
      }
    },
    G_PREVIEW_TASK_DAY() {
      this.selectedTaskDay = this["G_PREVIEW_TASK_DAY"];
      this.selectedTaskIndex = this["G_PREVIEW_TASK_INDEX"];
    },
    G_PREVIEW_TASK_INDEX() {
      this.selectedTaskDay = this["G_PREVIEW_TASK_DAY"];
      this.selectedTaskIndex = this["G_PREVIEW_TASK_INDEX"];
    },
  },
  methods: {
    // 데이터 가져오기 공통모듈
    async getData() {
      this.params.esntlId = this["G_PREVIEW_COURSE_ID"];
      const rs = await this.$axios({
        url: "/partner/preview/task",
        params: this.params,
      });
      if (rs.result === "SUC") {
        rs.resultList.forEach((result) => {
          const findIndex = this.arrData.findIndex(
            (item) => item.day === result.dayOrder
          );
          if (findIndex === -1) {
            const data = { day: result.dayOrder, content: [] };
            data.content.push(result);
            this.arrData.push(data);
          } else {
            this.arrData[findIndex].content.push(result);
            this.arrData[findIndex].content.sort(
              (a, b) => a.numberOrder - b.numberOrder
            );
          }
        });
        const selectedDayList = this.arrData.filter(
          (item) => item.day === this.selectedTaskDay
        );
        const selectedTask =
          selectedDayList.length > 0 && this.selectedTaskIndex > -1
            ? selectedDayList[0].content[this.selectedTaskIndex]
            : null;
        if (selectedTask) {
          this.setTaskData(
            selectedTask,
            this.selectedTaskDay,
            selectedDayList.length,
            this.selectedTaskIndex
          );
        }
      }
    },

    convertStatus(status) {
      if (status === "FIN") {
        return "완료";
      } else {
        return "미완료";
      }
    },

    async setTaskData(content, day, all, currentIndex) {
      await this.$store.dispatch(
        "A_PREVIEW_TASK_NAME",
        `${day}일차 - ${currentIndex + 1}/${all}`
      );
      await this.$store.dispatch("A_PREVIEW_TASK_TITLE", content.title);
      await this.$store.dispatch("A_PREVIEW_TASK_DAY", day);
      await this.$store.dispatch("A_PREVIEW_TASK_INDEX", currentIndex);
      await this.$store.dispatch(
        "A_PREVIEW_CUSTOMER_TASK_ID",
        content.customerTaskEsntlId
      );
      await this.$store.dispatch("A_PREVIEW_TASK_ID", content.esntlId);
    },
  },
};
</script>

<style scoped lang="scss">
.div-app {
  width: 375px;
  height: 80vh;
  background: var(--white);
  overflow-y: auto;
  &-title {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
  }
}
.div-app-list {
  &-header {
    width: 100%;
    padding: 20px 16px 0 16px;
    font-size: 16px;
    color: #888888;
  }
  &-content {
    width: 100%;
    padding: 16px;
  }
}
.div-app-list-header-title {
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 8px;
}
.div-app-list-content {
  cursor: pointer;
  &-main {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &-title {
    font-size: 16px;
  }
  &-type {
    font-size: 12px;
    color: #888888;
  }
  &-status {
    font-size: 12px;
    color: #ff755a;
    margin-right: 8px;
  }
  &-status-inactive {
    font-size: 12px;
    color: #888888;
  }
  &-date {
    font-size: 12px;
    color: #ff755a;
  }
}
.selected {
  background: #f0f0f0;
}
</style>
