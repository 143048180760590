export default {
  G_PAGE: state => {
    return Number(state.PAGE)
  },
  G_ORDER: state => {
    return state.ORDER
  },
  G_LSTCNT: state => {
    return Number(state.LSTCNT)
  },
  G_PGCNT: state => {
    return Number(state.PGCNT)
  },
  G_TOTCNT: state => {
    return Number(state.TOTCNT)
  },
}
