<template>
  <div class="w-100" style="padding: 32px 0 24px 0">
    <p clsss="emp-title" style="text-align: center">
      입력하신 정보로 가입된 계정이 없습니다.
    </p>
    <p class="emp-content" style="margin-top: 10px">
      가입하신 정보가 맞는지 확인해주세요.
    </p>
    <p class="emp-content">
      가입한 정보가 생각나지 않으신다면 파트너 고객센터로 문의주세요.
    </p>
    <div class="flex-justify-center" style="margin-top: 20px">
      <button-common :size="SIZE_T" :clr="CLR_W" @click="closeModal"
        >닫기</button-common
      >
    </div>
    <p class="emp-link" style="margin-top: 20px" @click="openKakao">
      메달 파트너 고객센터 문의하기
    </p>
  </div>
</template>

<script>
export default {
  name: "ModalEmp",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {};
  },

  /*** created ***/
  async mounted() {},

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    closeModal() {
      this.$store.dispatch("A_MODAL_TOGGLE", false);
    },
    openKakao() {
      this.closeModal();
      window.open("http://pf.kakao.com/_pxkxnQb/chat");
    },
  },
};
</script>

<style scoped lang="scss">
.emp-title {
  font-size: 16px;
  color: #12131a;
}
.emp-content {
  font-size: 12px;
  font-weight: 500;
  color: #828699;
  text-align: center;
}
.emp-link {
  font-size: 12px;
  font-weight: 500;
  color: #515466;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}
</style>
