<template>
  <!-- 정산정보 -->
  <div class="info-container">
    <template v-if="infoData">
      <!-- 헤더 -->
      <div class="info-header-wrap flex-space-between">
        <div class="flex-align-center">
          <span class="header-category"
            >{{ infoData.name }} {{ infoData.gender }}</span
          >
          <span class="header-sub-category">{{ esntlId }}</span>
        </div>
      </div>
      <!-- 생년월일 -->
      <div class="info-content-wrap mt-4">
        <span class="content-category">생년월일</span>
        <span>{{ infoData.birth }}</span>
      </div>
      <!-- 연락처 -->
      <div class="info-content-wrap" style="border-bottom: 1px solid #d8dae6">
        <span class="content-category">연락처</span>
        <span>{{ formatPhone(infoData.phone) }}</span>
      </div>
      <!-- 로그인 계정 - 이메일 -->
      <div class="info-content-wrap mt-4">
        <span class="content-category">로그인 계정</span>
        <div class="w-100 flex-space-between">
          <span :class="`${infoData.loginType === 'MAL' ? 'last-login' : ''}`"
            >이메일</span
          >
          <span
            :class="`${infoData.loginType === 'MAL' ? 'last-login' : ''}`"
            >{{ infoData.email }}</span
          >
        </div>
      </div>
      <!-- 로그인 계정 - 카카오 -->
      <div class="info-content-wrap">
        <span class="content-category"></span>
        <div class="w-100 flex-space-between">
          <span :class="`${infoData.loginType === 'KAO' ? 'last-login' : ''}`"
            >카카오</span
          >
          <span
            :class="`${infoData.loginType === 'KAO' ? 'last-login' : ''}`"
            >{{ infoData.kakaoEmail }}</span
          >
        </div>
      </div>
      <!-- 로그인 계정 - 네이버 -->
      <div class="info-content-wrap">
        <span class="content-category"></span>
        <div class="w-100 flex-space-between">
          <span :class="`${infoData.loginType === 'NVR' ? 'last-login' : ''}`"
            >네이버</span
          >
          <span
            :class="`${infoData.loginType === 'NVR' ? 'last-login' : ''}`"
            >{{ infoData.naverEmail }}</span
          >
        </div>
      </div>
      <!-- 로그인 계정 - 구글 -->
      <div class="info-content-wrap">
        <span class="content-category"></span>
        <div class="w-100 flex-space-between">
          <span :class="`${infoData.loginType === 'GLE' ? 'last-login' : ''}`"
            >구글</span
          >
          <span
            :class="`${infoData.loginType === 'GLE' ? 'last-login' : ''}`"
            >{{ infoData.googleEmail }}</span
          >
        </div>
      </div>
      <!-- 로그인 계정 - 애플 -->
      <div class="info-content-wrap" style="border-bottom: 1px solid #d8dae6">
        <span class="content-category"></span>
        <div class="w-100 flex-space-between">
          <span :class="`${infoData.loginType === 'APE' ? 'last-login' : ''}`"
            >애플</span
          >
          <span
            :class="`${infoData.loginType === 'APE' ? 'last-login' : ''}`"
            >{{ infoData.appleEmail }}</span
          >
        </div>
      </div>
      <!-- 앱 접속 -->
      <div class="info-content-wrap mt-4">
        <span class="content-category">앱 접속</span>
        <div class="w-100 flex-space-between">
          <span>{{ formatPrice(infoData.mobileNumber) }}건</span>
          <span style="color: #828699">{{ infoData.mobileDate }}</span>
        </div>
      </div>
      <!-- 웹 접속 -->
      <div class="info-content-wrap">
        <span class="content-category">웹 접속</span>
        <div class="w-100 flex-space-between">
          <span>{{ formatPrice(infoData.webNumber) }}건</span>
          <span style="color: #828699">{{ infoData.webDate }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalMemberCode",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      esntlId: "",
      infoData: null,
    };
  },

  /*** created ***/
  async mounted() {
    // await this.getMemeberCode();
  },

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE", "G_MEMBER_CODE"]),
  },

  /*** watch ***/
  watch: {
    G_MODAL_TOGGLE(val) {
      if (val) {
        this.esntlId = this["G_MEMBER_CODE"];
        this.getMemeberCode();
      } else {
        this.esntlId = "";
        this.infoData = null;
      }
    },
  },

  /*** methods ***/
  methods: {
    async getMemeberCode() {
      const rs = await this.$axios({
        url: "/admin/popup/member",
        params: { esntlId: this["G_MEMBER_CODE"] },
      });
      if (rs.result === "SUC") {
        this.infoData = rs.resultData;
      } else {
        //console.log("멤버코드 조회 실패");
      }
    },

    formatPrice(price) {
      return price ? price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
    },

    formatPhone(num) {
      if (!num) {
        return "";
      }
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  padding: 22px;
}
.info-header-wrap {
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-sub-category {
  color: #babdcc;
  font-size: 14px;
  font-weight: 700;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  min-width: 200px;
}
.last-login {
  color: #2ec8b5;
}
</style>
