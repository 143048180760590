<template>
  <div v-if="srcNm" :style="style" class="img-wrap"></div>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'PreviewImage',

  /*** mixins ***/
  mixins: [mixinForm],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {  },

  /*** props ***/
  props: {

  },

  /*** data ***/
  data() {
    return {

    }
  },

  /*** created ***/
  created() {

  },

  /*** computed ***/
  computed: {
    style() {
      let css = ''
      if(this.sizeNm) css += 'width: ' + this.sizeNm + 'px;'
      if(this.sizeNm) css += 'height: ' + this.sizeNm + 'px;'
      if(this.srcNm) css += 'background-image: url(\'' + this.srcNm + '\');'
      return css
    }
  },

  /*** watch ***/
  watch: {

  },

  /*** methods ***/
  methods: {

  },
}
</script>

<style scoped lang="scss">
.img-wrap {
  background-size: cover;
  background-position: center;
}
</style>
