import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCompositionApi from '@vue/composition-api'
import { sync } from 'vuex-router-sync'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VCalendar from 'v-calendar'
import VueMoment from 'vue-moment'
import VueDaumPostcode from "vue-daum-postcode"
import { VueEditor, Quill } from "vue2-editor";
import { ImageDrop } from "quill-image-drop-module";
import ImageResize from "quill-image-resize";

import 'swiper/css/swiper.css'
import '@/plugins/global-string'
import '@/plugins/global-filter'
import '@/plugins/global-variables'
import '@/plugins/global-svg'
import '@/plugins/global-components'

Vue.config.productionTip = false
Vue.config.warmHandler = (err, vm, info) => {
  console.error('==== ERROR.ST ====')
  console.log('%c == err ', 'color: #72950B')
  console.log(err)
  console.log('%c == component', 'color: #72950B')
  console.log(vm)
  console.log('%c == info', 'color: #72950B')
  console.log(info)
  console.error('==== ERROR.ED ====')
  return false
}
// Quill을 전역으로 등록
Vue.prototype.$quill = Quill
Vue.prototype.$imageDrop = ImageDrop
Vue.prototype.$imageResize = ImageResize

Vue.use(VueCompositionApi);
Vue.use(VueAwesomeSwiper);
Vue.use(VCalendar);
Vue.use(VueMoment);
Vue.use(VueDaumPostcode);
Vue.use(VueEditor);

Vue.component('LoadingBar', () => import('vue-loading-overlay'))
sync(store, router)

new Vue({
  router,
  store,
  created() {
    Vue.prototype.axios.interceptors.request.use(config => {
      store.dispatch('A_LOADING', true)
      return config
    }, error => {
       store.dispatch('A_LOADING', false)
      return Promise.reject(error)
    })
    Vue.prototype.axios.interceptors.response.use(response => {
      setTimeout(() => {
        store.dispatch('A_LOADING', false)
      }, 500)
      return response
    }, error => {
      store.dispatch('A_LOADING', false)
      return Promise.reject(error)
    })
  },
  render: h => h(App)
}).$mount('#app')

