const A_FOLDER_ADD = ({ commit }, payload) => {
  commit('M_FOLDER_ADD', payload)
}

const A_FOLDER_ID = ({ commit }, payload) => {
  commit('M_FOLDER_ID', payload)
}

const A_FOLDER_CUT = ({ commit }, payload) => {
  commit('M_FOLDER_CUT', payload)
}

const A_FOLDER_RESET = ({ commit }) => {
  commit('M_FOLDER_RESET')
}

export default { A_FOLDER_ADD, A_FOLDER_CUT, A_FOLDER_ID, A_FOLDER_RESET }
