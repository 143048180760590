<template>
  <div class="w-100">
    <div class="commission-header-wrap flex-space-between flex-align-center">
      <span>수수료 설정하기</span>
      <img
        src="@/assets/image/close.svg"
        style="cursor: pointer"
        @click="closeModal"
      />
    </div>
    <div class="w-100" style="padding: 20px">
      <div class="commission-content-wrap">
        <span class="commission-category">월 자동결제 수수료</span>
        <input-text
          style="width: 120px; min-width: 120px"
          v-model="params.commission"
        />
        <span class="ml-16">%</span>
        <span class="ml-16 error" v-if="!params.commission"
          >수수료를 입력하지 않으면 변경저장이 되지 않습니다.</span
        >
      </div>
      <div class="commission-content-wrap">
        <span class="commission-category">해지위약금 수수료</span>
        <input-text
          style="width: 120px; min-width: 120px"
          v-model="params.cancelPenalty"
        />
        <span class="ml-16">%</span>
        <span class="ml-16 error" v-if="!params.cancelPenalty"
          >수수료를 입력하지 않으면 변경저장이 되지 않습니다.</span
        >
      </div>
      <div class="commission-content-wrap">
        <span class="commission-category">계약기간</span>
        <select-box
          :opt-nm="SELT_COMMISSION_MONTH"
          :size="SIZE_M"
          style="width: 120px; min-width: 120px"
          v-model="params.contractPeriod"
        />
        <span class="ml-16 error" v-if="!params.contractPeriod"
          >계약기간을 입력해주세요.</span
        >
      </div>
      <div
        class="commission-content-wrap"
        style="align-items: flex-start; flex-direction: column; padding: 16px"
      >
        <p class="commission-category">특약사항</p>
        <text-box
          wp-cls="w-100"
          style="margin-top: 22px"
          v-model="params.specialContent"
        />
      </div>
    </div>
    <div class="commission-bottom-wrap">
      <button-common
        :size="SIZE_T"
        :clr="CLR_M"
        :disabled="!checkValid"
        @click="editCommission"
        >{{ isCommission ? "수정" : "설정" }}</button-common
      >
      <button-common
        :size="SIZE_T"
        :clr="CLR_W"
        wp-cls="mr-8"
        @click="closeModal"
        >취소</button-common
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalCommission",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      isCommission: true,
      params: {
        esntlId: "",
        commission: "",
        cancelPenalty: "",
        specialContent: "",
        contractPeriod: "",
      },
      rejectReason: "",
    };
  },

  /*** created ***/
  async mounted() {},

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE", "G_COMMISSION_IS", "G_COMMISSION_DATA"]),
    checkValid() {
      return (
        this.params.commission &&
        this.params.cancelPenalty &&
        this.params.contractPeriod
      );
    },
  },

  /*** watch ***/
  watch: {
    async G_MODAL_TOGGLE(val) {
      // 열림
      if (val) {
        this.isCommission = this["G_COMMISSION_IS"];
        this.setData();
      }
    },
  },

  /*** methods ***/
  methods: {
    setData() {
      const data = this["G_COMMISSION_DATA"];
      this.params.esntlId = data.esntlId ? data.esntlId : "";
      this.params.commission = data.commission ? data.commission : "";
      this.params.cancelPenalty = data.cancelPenalty ? data.cancelPenalty : "";
      this.params.specialContent = data.specialContent
        ? data.specialContent
        : "";
      this.params.contractPeriod = data.contractPeriod
        ? data.contractPeriod
        : "";
    },
    async editCommission() {
      const rs = await this.$axios({
        url: "/admin/partner/reg/commission",
        params: this.params,
      });
      if (rs.result === "SUC") {
        this.closeModal();
      } else {
        //console.log("수수료 설정 실패");
      }
    },
    closeModal() {
      this.$store.dispatch("A_MODAL_TOGGLE", false);
    },
  },
};
</script>

<style scoped lang="scss">
.commission-header-wrap {
  background: #f8f8fb;
  padding: 16px;
}
.commission-content-wrap {
  padding: 16px;
  display: flex;
  align-items: center;
}
.commission-category {
  color: #828699;
  font-weight: 500;
  min-width: 150px;
}
.commission-bottom-wrap {
  background: #f8f8fb;
  padding: 16px;
  display: flex;
  flex-direction: row-reverse;
}
.error {
  color: #f1431d;
  font-size: 14px;
}
</style>
