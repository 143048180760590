<template>
  <span class="svg-wrap" @click.stop="$emit('click')">
    <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg" :class="wpCls">
      <circle cx="10" cy="10" r="9" fill="#F1431D"></circle>
      <path d="M5 10H15" stroke="white"></path>
    </svg>
  </span>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'SvgRemoveRed',
  mixins: [mixinForm],

  /*** props ***/
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    viewBox: {
      type: String,
      default: '0 0 20 20'
    },
    fill: {
      type: String,
      default: 'none'
    },
  },
}
</script>

<style scoped lang="scss">

</style>









