export default {
  M_PAGE (state, payload) {
    state.PAGE = payload
  },
  M_ORDER (state, payload) {
    state.ORDER = payload
  },
  M_LSTCNT (state, payload) {
    state.LSTCNT = payload
  },
  M_PGCNT (state, payload) {
    state.PGCNT = payload
  },
  M_TOTCNT (state, payload) {
    state.TOTCNT = payload
  },
  M_PAGER_RESET(state) {
    state.PAGE = 1
    state.ORDER = 'DESC'
    state.LSTCNT = 10
    state.PGCNT = 10
    state.TOTCNT = 0
  }
}
