import _ from 'lodash'
export default {
  G_FOLDER_ARR: state => {
    return state.FOLDER
  },
  G_FOLDER_ID: (state) => {
    return state.ID
  },
  G_FOLDER: (state, id) => {
    return _.find(state.FOLDER, ['id', id])
  },
}
