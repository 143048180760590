import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import ALARM from './modules/alarm'
import NAV from './modules/nav'
import MODAL from './modules/modal'
import LOADING from './modules/loading'
import PAGER from './modules/pager'
import PAGER2 from './modules/pager2'
import FOLDER from './modules/folder'
import FOLDER2 from './modules/folder2'
import PREVIEW from './modules/preview'
import TASK from './modules/task'


Vue.use(Vuex)

const store = new Vuex.Store({
  modules: { ALARM, NAV, MODAL, LOADING, PAGER, PAGER2, FOLDER, FOLDER2, PREVIEW, TASK},
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    createPersistedState({ NAV, PAGER, FOLDER, PREVIEW })
  ]
})

export default store
