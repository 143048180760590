<template>
  <span class="svg-wrap" @click.stop="$emit('click')">
    <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0H20V20H0V0Z" fill="white"/>
      <path d="M14 12L9.99988 8L6 12" stroke="#BABDCC" stroke-linecap="round"/>
      <path d="M0 0V-1H-1V0H0ZM20 0H21V-1H20V0ZM20 20V21H21V20H20ZM0 20H-1V21H0V20ZM0 1H20V-1H0V1ZM19 0V20H21V0H19ZM20 19H0V21H20V19ZM1 20V0H-1V20H1Z" fill="#BABDCC"/>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'svg-folder',

  /*** props ***/
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    viewBox: {
      type: String,
      default: '0 0 20 20'
    },
    fill: {
      type: String,
      default: 'none'
    },
  },
}
</script>

<style scoped lang="scss">
svg {
  transform: rotate(180deg);
}
</style>
