<template>
  <div>
    <!-- 문제 -->
    <div v-html="data.data1" />
    <!-- 답 -->
    <div style="text-align: center" v-if="data.dataList">
      <p>{{ data.dataList.length }}회</p>
      <div
        class="flex-align-center"
        style="margin-top: 16px; position: relative"
        v-for="(data, index) in data.dataList"
        :key="index"
      >
        <span style="margin-right: 8px; font-size: 12px;">{{ index + 1 }}회 :</span>
        <span style="margin-right: 8px; font-size: 12px;">{{ data.date }} {{ data.time }}</span>
        <span style="margin-right: 8px; color: #888888; font-size: 12px;">{{ data.data2 }}</span>
        <img src="@/assets/image/mission_pen.svg" style="position: absolute; right: 0" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MissionCH",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  /*** data ***/
  data() {
    return {};
  },

  /*** created ***/
  created() {},

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    checkAnswer(answer, index) {
      if (!this.data.dataList[index]) {
        return false;
      }
      return answer.answerType === this.data.dataList[index].data;
    },
  },
};
</script>

<style scoped lang="scss">
.flex-align-center {
  display: flex;
  align-items: center;
}
</style>
