const A_NAV_ALL = ({ commit }, payload) => {
  commit('M_NAV_ALL', payload)
}

const A_NAV_ID = ({ commit }, payload) => {
  commit('M_NAV_ID', payload)
}

const A_NAV_SHOW = ({ commit }, payload) => {
  commit('M_NAV_SHOW', payload)
}

const A_NAV_LAST_PATH = ({ commit }, payload) => {
  commit('M_NAV_LAST_PATH', payload)
}

export default { A_NAV_ALL, A_NAV_ID, A_NAV_SHOW, A_NAV_LAST_PATH }
