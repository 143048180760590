<template>
  <div class="pagination d-flex align-items-center justify-content-center">
    <button @click="handlePrev">
      <svg-arrow-prev />
    </button>
    <div class="page-list d-flex">
      <a
          v-for="val in pager"
          :key="val"
          :title="val"
          :class="`page-number ${val === PAGE ? 'f-main' : ''}`"
          @click="handlePage"
      >{{ val }}</a>
    </div>
    <button @click="handleNext">
      <svg-arrow-next />
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ModalPagination',

  /*** data ***/
  data() {
    return {
      totPage: Math.ceil(this.TOTCNT / this.LSTCNT),
      prevPage: 0,
      nextPage: 0,
      stPage: 0,
      edPage: 0,
    }
  },

  /*** created ***/
  created() {
    this.changeData()
  },

  /*** computed ***/
  computed: {
    ...mapGetters({
      PAGE: 'PAGER2/G_PAGE',
      LSTCNT: 'PAGER2/G_LSTCNT',
      PGCNT: 'PAGER2/G_PGCNT',
      TOTCNT: 'PAGER2/G_TOTCNT',
    }),
    pager: {
      get() {
        const pages = [];
        for(let i=this.stPage; i<=this.edPage; i++) pages.push(i)
        //console.log('pages => ', pages)
        return pages
      },
      set() {
        
      }
    }
  },

  /*** watch ***/
  watch: {
    PAGE: function(val, oVal) {
      if(val !== oVal) this.changeData()
    },
    LSTCNT: function(val, oVal) {
      if(val !== oVal) this.changeData()
    },
    PGCNT: function(val, oVal) {
      if(val !== oVal) this.changeData()
    },
    TOTCNT: function(val, oVal) {
      if(val !== oVal) this.changeData()
    },
  },

  /*** methods ***/
  methods: {
    changeData() {
     
      this.totPage = Math.ceil(this.TOTCNT / this.LSTCNT)
      this.prevPage = this.PAGE > 1 ? this.PAGE - 1 : 1
      this.nextPage = this.PAGE < this.totPage ? this.PAGE + 1 : this.totPage
      this.stPage = Math.floor((this.PAGE - 1) / this.PGCNT) * this.PGCNT + 1
      this.edPage = this.stPage + this.PGCNT - 1 > this.totPage ? this.totPage : this.stPage + this.PGCNT - 1
      //console.log('%c ==== Pagination: S', 'color: #0A6F7A')
      //console.log('G_PAGE => ', this.PAGE)
      //console.log('G_TOTCNT => ', this.TOTCNT)
      //console.log('G_PGCNT => ', this.PGCNT)
      //console.log('G_LSTCNT => ', this.LSTCNT)
      //console.log('totPage => ', this.totPage)
      //console.log('prevPage => ', this.prevPage)
      //console.log('nextPage => ', this.nextPage)
      //console.log('stPage => ', this.stPage)
      //console.log('edPage => ', this.edPage)
      //console.log('%c ==== Pagination: E', 'color: #0A6F7A')
    },
    handlePrev() {
      this.$emit('click', this.prevPage)
    },
    handleNext() {
      this.$emit('click', this.nextPage)
    },
    handlePage(e) {
      this.$emit('click', e.target.title)
    }
  }
}
</script>

<style scoped>
button, a {
  cursor: pointer
}
</style>
