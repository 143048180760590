<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 6L12 10.0001L8 14" stroke="#3A3D4D" stroke-linecap="round"></path>
    <path d="M0 0L-0.8 0L-0.8 -0.8L0 -0.8L0 0ZM0 20L0 20.8H-0.8L-0.8 20H0ZM20 20H20.8V20.8H20V20ZM20 0V-0.8L20.8 -0.8V0L20 0ZM0.8 0L0.8 20H-0.8L-0.8 0L0.8 0ZM0 19.2L20 19.2V20.8L0 20.8L0 19.2ZM19.2 20L19.2 0L20.8 0L20.8 20H19.2ZM20 0.8L0 0.8L0 -0.8L20 -0.8V0.8Z" fill="#3A3D4D"></path>
  </svg>
</template>

<script>
export default {
  name: 'SvgArrowRt',

  /*** props ***/
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    viewBox: {
      type: String,
      default: '0 0 20 20'
    },
    fill: {
      type: String,
      default: 'none'
    },
  },
}
</script>

<style scoped>

</style>
