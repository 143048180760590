<template>
  <div :class="cls">
    <input type="radio" :id="labelId" :checked="checked" :value="valNm" @change="$emit('input', $event.target.value)">
    <label :for="labelId"><slot /></label>
  </div>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'InputRadio',
  mixins: [mixinForm],
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  data() {
    return {
      labelId: '',
    }
  },
  created() {
    this.labelId = this.$nnid()
  },
  computed: {
    checked() {
      return this.modelValue === this.valNm
    },
    cls() {
      let cls = ''
      cls += this.wpCls || ''
      return cls
    }
  },
}
</script>

<style scoped>
label {
  user-select: none;
}
</style>
