<template>
  <!-- 결제유형 -->
  <div class="info-container" v-if="infoData">
    <!-- 헤더 -->
    <div
      class="info-header-wrap flex-space-between"
      style="margin-bottom: 18px"
    >
      <div class="flex-align-center">
        <span class="header-category">{{ infoData.goodName }}</span>
        <span class="header-sub-category">{{ esntlId }}</span>
      </div>
    </div>
    <!-- 날짜 -->
    <div
      class="info-content-wrap"
      v-for="(data, index) in infoData.detailList"
      :key="index"
    >
      <span class="content-category">{{ data.regDate }}</span>
      <div class="w-100 flex-space-between">
        <span class="pay-confirm" v-if="!data.status && infoData.type === 'N'"
          >{{ data.currentNumber }}/{{ data.maxNumber }}개월</span
        >
        <span class="pay-confirm" v-if="!data.status && infoData.type === 'P'"
          >위약금</span
        >
        <span
          class="pay-ing"
          v-if="data.status === 'C_R' && infoData.type === 'N'"
          >결제취소 신청</span
        >
        <span
          class="pay-ing"
          v-if="data.status === 'C_R' && infoData.type === 'P'"
          >해지취소 신청</span
        >
        <span class="pay-cancel" v-if="data.status === 'C'">취소 완료</span>
        <span class="pay-cancel" v-if="data.status === 'C_J'">취소 거절</span>
        <span v-if="!data.status"
          >{{ formatPrice(data.price) }}원[{{ data.card }}]</span
        >
        <span class="pay-cancel" v-if="data.status === 'C'"
          >-{{ formatPrice(data.returnPrice) }}원[{{ data.card }}]</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalPaymentType",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      esntlId: "",
      infoData: null,
    };
  },

  /*** created ***/
  async mounted() {},

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE", "G_MEMBER_CODE"]),
  },

  /*** watch ***/
  watch: {
    G_MODAL_TOGGLE(val) {
      if (val) {
        this.esntlId = this["G_MEMBER_CODE"];
        this.getPaymentType();
      } else {
        this.esntlId = "";
        this.infoData = null;
      }
    },
  },

  /*** methods ***/
  methods: {
    async getPaymentType() {
      let url = localStorage.getItem('auth') === 'COM' ? "/partner/popup/pay/type" : "/admin/popup/pay/type"
      const rs = await this.$axios({
        url: url,
        params: { esntlId: this["G_MEMBER_CODE"] },
      });
      if (rs.result === "SUC") {
        this.infoData = rs.resultData;
      } else {
        //console.log("결제 유형 조회 실패");
      }
    },

    formatPrice(price) {
      return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    formatPhone(num) {
      if (!num) {
        return "";
      }
      if (/^(02|0[3-9]{1}[0-9]{1})-?[0-9]{3}-?[0-9]{4,}$/.test(num)) {
        if ((/^(02)/.test(num) && num.length >= 10) || num.length >= 13) {
          return num
            .replace(/[-]/g, "")
            .replace(
              /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
              "$1-$2-$3"
            );
        }
        return num.replace(
          /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{3})([0-9]{4})/,
          "$1-$2-$3"
        );
      }
      return num.replace(
        /(^02.{0}|^01.{1}|[0-9]{3})([0-9]{4})([0-9]{4})/,
        "$1-$2-$3"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
  padding: 22px;
}
.info-header-wrap {
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-sub-category {
  color: #babdcc;
  font-size: 14px;
  font-weight: 700;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 8px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  min-width: 200px;
}
.pay-confirm {
  color: #2ec8b5;
}
.pay-ing {
  color: #c4c4c4;
}
.pay-cancel {
  color: #fd765f;
}
</style>
