import Vue from 'vue'

/*** ICON ***/
import SvgFolder from '@/components/ui/icon/SvgFolder'
import SvgArrowPrev from '@/components/ui/icon/SvgArrowPrev'
import SvgArrowNext from '@/components/ui/icon/SvgArrowNext'
import SvgArrowUp from '@/components/ui/icon/SvgArrowUp'
import SvgArrowDn from '@/components/ui/icon/SvgArrowDn'
import SvgArrowLt from '@/components/ui/icon/SvgArrowLt'
import SvgArrowRt from '@/components/ui/icon/SvgArrowRt'
import SvgBars from '@/components/ui/icon/SvgBars'
import SvgRemoveRed from '@/components/ui/icon/SvgRemoveRed'


Vue.component('svg-folder', SvgFolder)
Vue.component('svg-arrow-prev', SvgArrowPrev)
Vue.component('svg-arrow-next', SvgArrowNext)
Vue.component('svg-arrow-up', SvgArrowUp)
Vue.component('svg-arrow-dn', SvgArrowDn)
Vue.component('svg-arrow-lt', SvgArrowLt)
Vue.component('svg-arrow-rt', SvgArrowRt)
Vue.component('svg-bars', SvgBars)
Vue.component('svg-remove-red', SvgRemoveRed)




