<template>
  <li class="menuAll_button" @click="showAll">
    
  </li>
</template>

<script>
export default {
  name: 'NavAll',
  methods: {
    showAll() {
      //console.log("AllMenu")
    }
  }
}
</script>

<style scoped lang="scss">

</style>
