
export default {
  G_IS_MEMBER: (state) => {
    return state.IS_MEMBER
  },
  G_ALARM_DATA: (state) => {
    return state.ALARM_DATA
  },
  G_SHOW_BELL: (state) => {
    return state.SHOW_BELL
  },
  G_BELL_COUNT: (state) => {
    return state.BELL_COUNT
  },
}
