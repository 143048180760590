<template>
  <div class="w-100">
    <div class="commission-header-wrap flex-space-between flex-align-center">
      <span>홈 안내글 설정</span>
      <img
        src="@/assets/image/close.svg"
        style="cursor: pointer"
        @click="closeModal"
      />
    </div>
    <div class="w-100" style="padding: 20px">
      <div class="commission-content-wrap">
        <span class="commission-category">카테고리</span>
        <span class="commission-category">{{ category }}</span>
      </div>
      <div class="commission-content-wrap" style="align-items: flex-start">
        <p class="commission-category">내용</p>
        <text-box wp-cls="w-100" v-model="params.content" />
      </div>
      <div class="commission-content-wrap">
        <span class="commission-category">유지기간</span>
        <select-box
          :opt-nm="SELT_APPINFO_EXPIREDATE"
          :size="SIZE_M"
          v-model="params.expiryDate"
        />
      </div>
    </div>
    <div class="commission-bottom-wrap">
      <button-common :size="SIZE_T" :clr="CLR_M" @click="editData"
        >수정</button-common
      >
      <button-common
        :size="SIZE_T"
        :clr="CLR_W"
        wp-cls="mr-8"
        @click="closeModal"
        >취소</button-common
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalCommission",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      category: "",
      params: {
        esntlId: "",
        content: "",
        expiryDate: "NULL",
      },
    };
  },

  /*** created ***/
  async mounted() {},

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE", "G_APPINFO_DATA"]),
  },

  /*** watch ***/
  watch: {
    async G_MODAL_TOGGLE(val) {
      // 열림
      if (val) {
        this.setData();
      }
    },
  },

  /*** methods ***/
  methods: {
    setData() {
      const data = this["G_APPINFO_DATA"];
      if (data) {
        this.params.esntlId = data.esntlId ? data.esntlId : "";
        this.category = data.categoryTitle ? data.categoryTitle : "";
        this.params.content = data.content ? data.content : "";
        this.params.expiryDate = data.expiryDate ? data.expiryDate : "NULL";
      }
    },
    async editData() {
      const rs = await this.$axios({
        url: "/admin/system/app/update",
        params: this.params,
      });
      if (rs.result === "SUC") {
        this.closeModal();
      } else {
        //console.log("안내글 수정 실패");
      }
    },
    closeModal() {
      this.$store.dispatch("A_MODAL_TOGGLE", false);
    },
  },
};
</script>

<style scoped lang="scss">
.commission-header-wrap {
  background: #f8f8fb;
  padding: 16px;
}
.commission-content-wrap {
  padding: 16px;
  display: flex;
  align-items: center;
}
.commission-category {
  color: #828699;
  font-weight: 500;
  min-width: 150px;
}
.commission-bottom-wrap {
  background: #f8f8fb;
  padding: 16px;
  display: flex;
  flex-direction: row-reverse;
}
</style>
