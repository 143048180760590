import { render, staticRenderFns } from "./NavMain.vue?vue&type=template&id=0638ae4f&scoped=true"
import script from "./NavMain.vue?vue&type=script&lang=js"
export * from "./NavMain.vue?vue&type=script&lang=js"
import style0 from "./NavMain.vue?vue&type=style&index=0&id=0638ae4f&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0638ae4f",
  null
  
)

export default component.exports