<template>
  <div>
    <div v-html="data.dataList[0].data" v-if="data.dataList.length > 0" />
  </div>
</template>

<script>
export default {
  name: "MissionTE",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  /*** data ***/
  data() {
    return {};
  },

  /*** created ***/
  created() {},

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {},
};
</script>

<style scoped lang="scss"></style>
