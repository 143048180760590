<template>
  <vue-timepicker :value="value" :class="cls" @input="handleChange" :placeholder="holderNm" input-width="8em"/>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'TimePickerBox',
  mixins: [mixinForm],

  /*** components ***/
  components: { VueTimepicker },

  /*** props ***/
  props: ['value'],

  /*** computed ***/
  computed: {
    cls() {
      let cls = ''
      cls += this.wpCls || ''
      cls += ' '
      cls += this.size || ''
      return cls
    },
  },

  /*** methods ***/
  methods: {
    handleChange(val) {
      this.$emit('input', val)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
