import axios from "axios";

const A_ALARM_DATA = ({ commit }, payload) => {
  commit("M_ALARM_DATA", payload);
};
const A_IS_MEMBER = ({ commit }, payload) => {
  commit("M_IS_MEMBER", payload);
};
const A_SHOW_BELL = ({ commit }, payload) => {
  commit("M_SHOW_BELL", payload);
};

const A_BELL_COUNT = async ({ commit }) => {
  const rs = await axios({
    method: "POST",
    url: "https://medal.sailors.kr/partner/alarm/count",
    data: null,
    proxy: true,
    withCredentials: true,
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
  });
  if (rs.data.result === "SUC") {
    commit("M_BELL_COUNT", rs.data.alarmCnt);
  }
};

export default {
  A_ALARM_DATA,
  A_IS_MEMBER,
  A_SHOW_BELL,
  A_BELL_COUNT,
};
