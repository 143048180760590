<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg" :class="wpCls">
    <path d="M8 10H24" stroke="#828699" stroke-width="2" stroke-linecap="round"></path>
    <path d="M8 16H24" stroke="#828699" stroke-width="2" stroke-linecap="round"></path>
    <path d="M8 22H24" stroke="#828699" stroke-width="2" stroke-linecap="round"></path>
  </svg>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'svg-folder',
  mixins: [mixinForm],

  /*** props ***/
  props: {
    width: {
      type: Number,
      default: 32
    },
    height: {
      type: Number,
      default: 32
    },
    viewBox: {
      type: String,
      default: '0 0 32 32'
    },
    fill: {
      type: String,
      default: 'none'
    },
  },
}
</script>

<style scoped lang="scss">

</style>




