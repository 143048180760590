<template>
  <div class="current-bar pyx mb-16" v-if="G_NAV_SIBLINGS.length">
    <router-link
        v-for="nav in G_NAV_SIBLINGS"
        :key="nav.id"
        :to="nav.path"
        :class="`depth2 fm-16 ${G_NAV_ID.startsWith(nav.id) ? 'selected' : ''}`"
    >
      {{ nav.title }}
    </router-link>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SubTitle',
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['G_NAV_ID', 'G_NAV_SIBLINGS'])
  },
  mounted() {
   
},
}
</script>

<style scoped lang="scss">
.current-bar {
  border: 1px solid var(--gray20);
  background: var(--white);
  margin: 0 auto;
}
</style>
