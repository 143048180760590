<template>
  <!-- 사업자 정보 -->
  <div class="info-container" v-if="infoData">
    <!-- 헤더 -->
    <div class="info-header-wrap flex-space-between">
      <div class="flex-align-center">
        <span class="header-category">사업자 정보</span>
      </div>
    </div>
    <!-- 상호명 -->
    <div class="info-content-wrap">
      <span class="content-category">상호명</span>
      <span>{{ infoData.compName }}</span>
    </div>
    <!-- 사업자등록번호 -->
    <div class="info-content-wrap">
      <span class="content-category">사업자등록번호</span>
      <span>{{ infoData.compRegNumber }}</span>
    </div>
    <!-- 대표자 이름 -->
    <div class="info-content-wrap">
      <span class="content-category">대표자 이름</span>
      <span>{{ infoData.compCeoName }}</span>
    </div>
    <!-- 사업장소재지 -->
    <div class="info-content-wrap">
      <span class="content-category">사업장소재지</span>
      <span>{{ infoData.compAddr }} {{ infoData.compAddr2 }}</span>
    </div>
    <!-- 사업자 구분 -->
    <div class="info-content-wrap">
      <span class="content-category">사업자 구분</span>
      <span>{{ infoData.compClass }}</span>
    </div>
    <!-- 업태 -->
    <div class="info-content-wrap">
      <span class="content-category">업태</span>
      <span>{{ infoData.compStatus }}</span>
    </div>
    <!-- 업종 -->
    <div class="info-content-wrap">
      <span class="content-category">업종</span>
      <span>{{ infoData.compType }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalBusinessInfo",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      infoData: null,
    };
  },

  /*** created ***/
  async mounted() {
    await this.getBusinessData();
  },

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    async getBusinessData() {
      const rs = await this.$axios({
        url: "/partner/myInfo/comp/data",
        params: {},
      });
      if (rs.result === "SUC") {
        this.infoData = rs.resultData.editData;
      } else {
        //console.log("사업자정보 조회 실패");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.info-container {
  width: 100%;
  background: #fff;
  border: 1px solid #d8dae5;
}
.info-header-wrap {
  width: 100%;
  padding: 16px 32px;
  border-bottom: 1px solid #d8dae6;
}
.header-category {
  color: #515466;
  font-size: 16px;
  font-weight: 700;
  margin-right: 32px;
}
.header-status-complete {
  color: #2CCDC3;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-reject {
  color: #f1431d;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-process {
  color: #515466;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
}
.header-status-date {
  color: blue;
  text-decoration: underline;
  font-size: 14px;
  font-weight: 500;
  margin-right: 16px;
  cursor: pointer;
}
.header-status-reason {
  color: blue;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.info-content-wrap {
  padding: 16px 0;
  display: flex;
  align-items: center;
}
.content-category {
  color: #828699;
  padding-left: 32px;
  width: 200px;
}
</style>
