<template>
  <div class="d-flex column justify-content-center align-items-center" @click="handleChangeCommon">
    <img :src="src">
    <span class="f-gray fr-14 mt-8">
      <slot />
    </span>
  </div>
</template>

<script>
/* *
* typeNm: IMG/IMG_P/DOC/DOC_P/MOVIE/MOVIE_P/CLIP/CLIP_P/FILE/FILE_P/LINK/LINK_P/MISSION
*  */
import mixinForm from '@/mixins/mixinForm'
import img from '@/assets/image/icon/30px/image.png'
import imgP from '@/assets/image/icon/30px/plus-image.png'
import text from '@/assets/image/icon/30px/text.png'
import textP from '@/assets/image/icon/30px/plus-text.png'
import video from '@/assets/image/icon/30px/video.png'
import videoP from '@/assets/image/icon/30px/plus-video.png'
import youtube from '@/assets/image/icon/30px/youtube.png'
import youtubeP from '@/assets/image/icon/30px/plus-youtube.png'
import file from '@/assets/image/icon/30px/file.png'
import fileP from '@/assets/image/icon/30px/plus-file.png'
import link from '@/assets/image/icon/30px/link.png'
import linkP from '@/assets/image/icon/30px/plus-link.png'
import mission from '@/assets/image/icon/30px/mission.png'


export default {
  name: 'IconMedia',
  mixins: [mixinForm],
  props: {

  },
  data() {
    return {

    }
  },
  computed: {
    src() {
      
      switch (this.typeNm) {
        case 'IMG':
          return img
        
        case 'IMG_P':
          return imgP
        
        case 'TEXT':
          return text
        
        case 'TEXT_P':
          return textP
        
        case 'VIDEO':
          return video
        
        case 'VIDEO_P':
          return videoP
        
        case 'YOUTUBE':
          return youtube
      
        case 'YOUTUBE_P':
          return youtubeP
      
        case 'FILE':
          return file
      
        case 'FILE_P':
          return fileP
      
        case 'LINK':
          return link
      
        case 'LINK_P':
          return linkP
      
        case 'MISSION':
          return mission
      
        default:
          return 'https://via.placeholder.com/30'
      
      }
      
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="scss">

</style>
