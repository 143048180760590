export default [
  {
    id: "0",
    title: "로그인",
    auth: ["COM", "ADM"],
    sub: [
      {
        id: "0-1",
        title: "로그인",
        redirect: "/login",
        path: "/",
        sub: [
          { id: "0-1-1", title: "로그인", name: "PartnerLogin", path: "/login" },
          {
            id: "0-1-2",
            title: "파트너 가입 신청",
            name: "Apply",
            path: "/sign/apply",
          },
          { id: "0-1-3", title: "본인 인증", name: "Auth", path: "/sign/auth" },
          { id: "0-1-4", title: "이메일", name: "Email", path: "/sign/email" },
          {
            id: "0-1-5",
            title: "주소",
            name: "Address",
            path: "/sign/address",
          },
          {
            id: "0-1-6",
            title: "카테고리",
            name: "Category",
            path: "/sign/category",
          },
          {
            id: "0-1-7",
            title: "가입동의",
            name: "Agree",
            path: "/sign/agree",
          },
          {
            id: "0-1-8",
            title: "가입완료",
            name: "Finish",
            path: "/sign/finish",
          },
          {
            id: "0-1-9",
            title: "관리자로그인",
            name: "Login",
            path: "/operator",
          },
          {
            id: "0-1-10",
            title: "사업자 인증",
            name: "Business",
            path: "/sign/business",
          },
          {
            id: "0-1-11",
            title: "휴대폰 인증",
            name: "Phone",
            path: "/sign/phone",
          },
          {
            id: "0-1-12",
            title: "아이디 찾기",
            name: "FindID",
            path: "/sign/find-id",
          },
          {
            id: "0-1-13",
            title: "패스워드 찾기",
            name: "FindPW",
            path: "/sign/find-pw",
          },
        ],
      },
    ],
  },
  // {
  //   id: '1', title: '운영계정관리', sub: [
  //     {
  //       id: '1-1', title: '운영계정내역', redirect: '/account/status', path: '/account', sub: [
  //         { id: '1-1-1', title: '계정현황', name: 'AccountStatus', path: '/account/status', sub: [
  //             { id: '1-1-1-1', title: '계정 수정', name: 'AccountEdit', path: '/account/edit/:id' },
  //           ]},
  //         { id: '1-1-2', title: '계정추가', name: 'AccountAdd', path: '/account/add' },
  //       ]
  //     },
  //   ]
  // },
  {
    id: '2', title: '콘텐츠관리', auth: ["COM"], sub: [
      {
        id: '2-1', title: '코스제작관리', redirect: '/content/course/task', path: '/content/course', sub: [
          { id: '2-1-1', title: '태스크', name: 'ContentCourseTask', path: '/content/course/task', sub: [
              { id: '2-1-1-1', title: '태스크 등록', name: 'ContentCourseTaskAdd', path: '/content/course/task/add' },
              { id: '2-1-1-2', title: '태스크 수정', name: 'ContentCourseTaskAdd', path: '/content/course/task/edit/:id' },
            ]
          },
          { id: '2-1-2', title: '코스', name: 'ContentCourseList', path: '/content/course/list', sub: [
              { id: '2-1-2-1', title: '코스 등록', name: 'ContentCourseAdd', path: '/content/course/add' },
              { id: '2-1-2-2', title: '코스 수정', name: 'ContentCourseAdd', path: '/content/course/edit/:id' },
            ]
          },
        ]
      },
      {
        id: '2-2', title: '영상제작관리', redirect: '/content/video/scene', path: '/content/video', sub: [
          { id: '2-2-1', title: '씬', name: 'ContentVideoScene', path: '/content/video/scene' },
          { id: '2-2-2', title: '영상', name: 'ContentVideoClip', path: '/content/video/clip' },
        ]
      },
    ]
  },
  {
    id: '3', title: '코스판매등록관리',  auth: ["COM"], sub: [
      {
        id: '3-1', title: '코스판매등록', redirect: '/sale/request/course', path: '/sale/request', sub: [
          { id: '3-1-1', title: '판매코스등록', name: 'SaleRequestCourse', path: '/sale/request/course' },
          { id: '3-1-2', title: '일반코스등록', name: 'SaleRequestNormal', path: '/sale/request/normal' },
        ]
      },
      {
        id: '3-2', title: '코스판매현황', redirect: '/sale/status/course', path: '/sale/status', sub: [
          { id: '3-2-1', title: '판매코스', name: 'SaleStatusCourse', path: '/sale/status/course' },
          { id: '3-2-2', title: '일반코스', name: 'SaleStatusNormal', path: '/sale/status/normal' },
        ]
      },
      {
        id: '3-3', title: '코스판매내역', redirect: '/sale/history/proceed', path: '/sale/history', sub: [
          { id: '3-3-1', title: '판매내역', name: 'SaleHistoryProceed', path: '/sale/history/proceed' },
          { id: '3-3-2', title: '취소내역', name: 'SaleHistoryCancel', path: '/sale/history/cancel' },
        ]
      },
      {
        id: '3-4', title: '판매코스수정', name: 'SaleRequestCourse', path: '/sale/request/course/:id', sub: [], isNotShow: true
      },
      {
        id: '3-5', title: '일반코스수정', name: 'SaleRequestNormal', path: '/sale/request/normal/:id' , sub: [], isNotShow: true
      },
    ]
  },
  {
    id: '4', title: '코스진행관리',  auth: ["COM"], sub: [
      {
        id: '4-1', title: '코스진행관리', redirect: '/course/progress/proceed', path: '/course/progress', sub: [
          { id: '4-1-1', title: '진행중 코스', name: 'CourseProgressProceed', path: '/course/progress/proceed' },
          { id: '4-1-2', title: '완료된 코스', name: 'CourseProgressCompletion', path: '/course/progress/completion' },
        ]
      },
      {
        id: '4-2', title: '피드백관리', redirect: '/course/feedback/request', path: '/course/feedback', sub: [
          { id: '4-2-1', title: '요청된피드백', name: 'CourseFeedbackRequest', path: '/course/feedback/request', sub: [
            { id: '4-2-1-1', title: '피드백 등록', name: 'CourseFeedbackRequestAdd', path: '/course/feedback/request/add' },
          ]},
          { id: '4-2-2', title: '완료된피드백', name: 'CourseFeedbackCompletion', path: '/course/feedback/completion', sub: [
            { id: '4-2-2-1', title: '피드백 등록', name: 'CourseFeedbackRequestAdd', path: '/course/feedback/request/add' },
          ]},
        ]
      },
    ]
  },
  {
    id: '5', title: '멤버관리',  auth: ["COM"], sub: [
      {
        id: '5-1', title: '멤버가입정보', redirect: '/member/sign/proceed', path: '/member/sign', sub: [
          { id: '5-1-1', title: '가입중멤버', name: 'MemberSignProceed', path: '/member/sign/proceed' },
          { id: '5-1-2', title: '가입해지멤버', name: 'MemberSignCancel', path: '/member/sign/cancel' },
        ]
      },
      {
        id: '5-2', title: '멤버구매내역', redirect: '/member/purchase/proceed', path: '/member/purchase', sub: [
          { id: '5-2-1', title: '구매현황', name: 'MemberPurchaseProceed', path: '/member/purchase/proceed' },
          { id: '5-2-2', title: '구매취소현황', name: 'MemberPurchaseCancel', path: '/member/purchase/cancel' },
        ]
      },
    ]
  },
  {
    id: '6', title: '코치관리',  auth: ["COM"], sub: [
      {
        id: '6-1', title: '코치신청내역', redirect: '/coach/application/proceed', path: '/coach/application', sub: [
          { id: '6-1-1', title: '신청현황', name: 'CoachApplicationProceed', path: '/coach/application/proceed' },
          { id: '6-1-2', title: '신청승인현황', name: 'CoachApplicationApproval', path: '/coach/application/approval' },
        ]
      },
      { id: '6-2', title: '코치등록내역', name: 'CoachMaster', path: '/coach/master' },
      {
        id: '6-3', title: '계약내역', redirect: '/coach/contract/status', path: '/coach/contract', sub: [
          { id: '6-3-1', title: '계약신청현황', name: 'CoachContractStatus', path: '/coach/contract/status' },
          { id: '6-3-2', title: '계약승인현황', name: 'CoachContractApproval', path: '/coach/contract/approval' },
        ]
      },
    ]
  },
  {
    id: '7', title: '정산관리',  auth: ["COM"], sub: [
      {
        id: '7-1', title: '전체판매내역', redirect: '/calculate/total/sale', path: '/calculate/total', sub: [
          { id: '7-1-1', title: '판매현황', name: 'CalculateTotalSale', path: '/calculate/total/sale' },
          { id: '7-1-2', title: '매출현황', name: 'CalculateTotalStatus', path: '/calculate/total/status' },
        ]
      },
      {
        id: '7-2', title: '코치정산내역', name: 'CalculateMaster', path: '/calculate/master'
      },
      {
        id: '7-3', title: '세금계산서', name: 'CalculateTax', path: '/calculate/tax', sub: [
          // { id: '7-3-1', title: '발급내역', name: '', name: '/' },
          // { id: '7-3-2', title: '수취내역', name: '', name: '/' },
        ]
      },
    ]
  },
  {
    id: '8', title: '알림관리',  auth: ["COM"], sub: [
      {
        id: '8-1', title: '사용자알림', redirect: '/alarm/user/status', path: '/alarm/user', sub: [
          { id: '8-1-1', title: '알림현황', name: 'AlarmUserStatus', path: '/alarm/user/status' },
          { id: '8-1-2', title: '즉시알림', name: 'AlarmUserInstant', path: '/alarm/user/instant' },
          { id: '8-1-3', title: '자동알림', name: 'AlarmUserAutomatic', path: '/alarm/user/automatic' },
        ]
      },
      {
        id: '8-2', title: '관리자알림', redirect: '/alarm/admin/status', path: '/alarm/admin', sub: [
          { id: '8-2-1', title: '알림현황', name: 'AlarmAdminStatus', path: '/alarm/admin/status' },
          { id: '8-2-2', title: '즉시알림', name: 'AlarmAdminInstant', path: '/alarm/admin/instant' },
          { id: '8-2-3', title: '자동알림', name: 'AlarmAdminAutomatic', path: '/alarm/admin/automatic' },
        ]
      },
    ]
  },
  {
    id: '9', title: '공지사항',  auth: ["COM"], sub: [
      {
        id: '9-1', title: '공지사항', redirect: '/board/notice/admin', path: '/board/notice', sub: [
          { id: '9-1-1', title: '관리자', name: 'BoardNoticeAdmin', path: '/board/notice/admin' },
          { id: '9-1-2', title: '사용자앱', name: 'BoardNoticeUser', path: '/board/notice/user' },
        ]
      },
    ]
  },
]
