export default {
  props: {
    // Attributes
    wpCls: {
      type: String,
      default: '',
    },
    wpCls2: {
      type: String,
      default: '',
    },
    wpCls3: {
      type: String,
      default: '',
    },
    frmCls: {
      type: String,
      default: '',
    },
    lblCls: {
      type: String,
      default: '',
    },
    lblNm: {
      type: String,
      default: '&nbsp;',
    },
    tlNm: {
      type: String,
      default: '',
    },
    contNm: {
      type: String,
      default: '',
    },
    msgNm: {
      type: String,
      default: '',
    },
    valNm: {
      type: String,
      default: ''
    },
    valNm2: {
      type: Boolean,
      default: false
    },
    optNm: {
      type: Array,
      default: () => ([])
    },
    srcNm: {
      type: String,
      default: ''
    },
    holderNm: {
      type: String,
      default: ''
    },
    typeNm: {
      type: String,
      default: ''
    },
    limitNm: {
      type: Number,
      default: 100
    },
    sizeNm: {
      type: Number,
      default: 100
    },
    
    // Properties
    useCounter: {
      type: Boolean,
      default: false
    },
    isShow: {
      type: Boolean,
      default: false
    },
    isShowLabel: {
      type: Boolean,
      default: true
    },
    isAlert: {
      type: Boolean,
      default: false
    },
    isChecked: {
      type: Boolean,
      default: false
    },
    
    // Class
    size: {
      type: String, // tiny, small, medium, large, full
      default: ''
    },
    clr: {
      type: String,
      default: ''
    },
  },
  
  // Methods
  methods: {
    handleChangeCommon(e) {
      this.$emit(e.type, e.target.value)
    },
  }
}

