<template>
  <select :value="value" :class="cls" @input="handleChange">
    <option v-for="(item, idx) in optNm" :key="idx" :value="item.value" :selected="value === item.value">{{ item.title }}</option>
  </select>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'SelectBox',
  mixins: [mixinForm],

  /*** props ***/
  props: ['value'],

  /*** computed ***/
  computed: {
    cls() {
      let cls = ''
      cls += this.wpCls || ''
      cls += ' '
      cls += this.size || ''
      return cls
    },
  },

  /*** methods ***/
  methods: {
    handleChange(e) {
      this.$emit('input', e.target[e.target.selectedIndex]._value)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
