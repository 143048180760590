<template>
  <div class="bell-alert pb-16 under-finish">
    <div class="
              d-flex
              space-between
              bell-alert-header
              p-16
              border-bottom
              align-items-center
            ">
      <span class="fm-14 f-g70">전체알람</span>
      <div>
        <span class="fm-14 f-g40">읽은알람 삭제</span>
        <span class="f-red ml-16 fm-14">전체삭제</span>
      </div>
    </div>
    <div class="bell-alert-body d-flex space-between align-items-center">
      <div class="bell-alert-title pt-16">
        <span class="fr-12 f-g60 p-8">2022.01.01 00:00</span>
        <div class="fb-14 p-8 f-g70">
          메세지 내용이 이 곳에 보여집니다.
        </div>
      </div>
      <div class="
                bell-alert-delete
                d-flex
                justify-content-center
                align-items-center
              "></div>
    </div>
    <div class="bell-alert-body d-flex space-between align-items-center">
      <div class="bell-alert-title pt-16">
        <span class="fr-12 f-g60 p-8">2022.01.01 00:00</span>
        <div class="fb-14 p-8 f-g70">
          메세지 내용이 이 곳에 보여집니다.
        </div>
      </div>
      <div class="
                bell-alert-delete
                d-flex
                justify-content-center
                align-items-center
              "></div>
    </div>
    <div class="bell-alert-body d-flex space-between align-items-center">
      <div class="bell-alert-title pt-16">
        <span class="fr-12 f-g60 p-8">2022.01.01 00:00</span>
        <div class="fb-14 p-8 f-g70">
          메세지 내용이 이 곳에 보여집니다.
        </div>
      </div>
      <div class="
                bell-alert-delete
                d-flex
                justify-content-center
                align-items-center
              "></div>
    </div>
    <div class="bell-alert-body d-flex space-between align-items-center">
      <div class="bell-alert-title pt-16">
        <span class="fr-12 f-g60 p-8">2022.01.01 00:00</span>
        <div class="fb-14 p-8 f-g70">
          메세지 내용이 이 곳에 보여집니다.
        </div>
      </div>
      <div class="
                bell-alert-delete
                d-flex
                justify-content-center
                align-items-center
              "></div>
    </div>
    <div class="bell-alert-body d-flex space-between align-items-center">
      <div class="bell-alert-title pt-16">
        <span class="fr-12 f-g60 p-8">2022.01.01 00:00</span>
        <div class="fb-14 p-8 f-g70">
          메세지 내용이 이 곳에 보여집니다.
        </div>
      </div>
      <div class="
                bell-alert-delete
                d-flex
                justify-content-center
                align-items-center
              "></div>
    </div>
    <div class="bell-alert-body d-flex space-between align-items-center">
      <div class="bell-alert-title pt-16">
        <span class="fr-12 f-g60 p-8">2022.01.01 00:00</span>
        <div class="fb-14 p-8 f-g70">
          메세지 내용이 이 곳에 보여집니다.
        </div>
      </div>
      <div class="
                bell-alert-delete
                d-flex
                justify-content-center
                align-items-center
              "></div>
    </div>
    <div class="bell-alert-body d-flex space-between align-items-center">
      <div class="bell-alert-title pt-16">
        <span class="fr-12 f-g60 p-8">2022.01.01 00:00</span>
        <div class="fb-14 p-8 f-g70">
          메세지 내용이 이 곳에 보여집니다.
        </div>
      </div>
      <div class="
                bell-alert-delete
                d-flex
                justify-content-center
                align-items-center
              "></div>
    </div>

    <div class="bell-alert-body d-flex space-between align-items-center">
      <div class="bell-alert-title pt-16">
        <span class="fr-12 f-g60 p-8">2022.01.01 00:00</span>
        <div class="fb-14 p-8 f-g70">
          메세지 내용이 이 곳에 보여집니다.
        </div>
      </div>
      <div class="
                bell-alert-delete
                d-flex
                justify-content-center
                align-items-center
              "></div>
    </div>
    <div class="bell-alert-body d-flex space-between align-items-center">
      <div class="bell-alert-title pt-16">
        <span class="fr-12 f-g60 p-8">2022.01.01 00:00</span>
        <div class="fb-14 p-8 f-g70">
          메세지 내용이 이 곳에 보여집니다.
        </div>
      </div>
      <div class="
                bell-alert-delete
                d-flex
                justify-content-center
                align-items-center
              "></div>
    </div>
    <div class="bell-alert-body d-flex space-between align-items-center">
      <div class="bell-alert-title pt-16">
        <span class="fr-12 f-g60 p-8">2022.01.01 00:00</span>
        <div class="fb-14 p-8 f-g70">
          메세지 내용이 이 곳에 보여집니다.
        </div>
      </div>
      <div class="
                bell-alert-delete
                d-flex
                justify-content-center
                align-items-center
              "></div>
    </div>
    <div class="bell-alert-body d-flex space-between align-items-center">
      <div class="bell-alert-title pt-16">
        <span class="fr-12 f-g60 p-8">2022.01.01 00:00</span>
        <div class="fb-14 p-8 f-g70">
          메세지 내용이 이 곳에 보여집니다.
        </div>
      </div>
      <div class="
                bell-alert-delete
                d-flex
                justify-content-center
                align-items-center
              "></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BellWrap'
}
</script>

<style scoped>

</style>
