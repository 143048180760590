<template>
  <div class="path-header d-flex space-between align-items-center">
    <div class="path fm-16 f-main">
      <span class="f-main pointer" @click="onChangeFolder('')">Home</span>
      <span class="fr-14 pointer" v-for="folder in $store.getters['FOLDER2/G_FOLDER_ARR']" :key="folder.id" @click="onChangeFolder(folder.id)"> &gt; {{ folder.name }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PathContent2',

  /*** data ***/
  data() {
    return {
      folders: [],
    }
  },

  /*** computed ***/
  computed: {
    ...mapGetters(['FOLDER2/G_FOLDER', 'FOLDER2/G_FOLDER_ID', 'FOLDER2/G_FOLDER_ARR'])
  },

  /*** watch ***/
  watch: {

  },

  /*** methods ***/
  methods: {
    onChangeFolder(id) {
      if(id === '') {
        this.$store.dispatch('FOLDER2/A_FOLDER_RESET')
      }
      else {
        this.$store.dispatch('FOLDER2/A_FOLDER_ID', id)
        this.$store.dispatch('FOLDER2/A_FOLDER_CUT', id)
      }
      this.$emit('change')
    }
  }
}
</script>

<style scoped class="scss">

</style>
