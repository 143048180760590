<template>
  <div>
    <!-- 문제 -->
    <div v-html="data.data1" />
    <!-- 답 -->
    <div style="margin-top: 16px" v-if="data.answerList">
      <div
        class="flex-align-center"
        v-for="(answer, index) in data.answerList"
        :key="index"
      >
        <img
          src="@/assets/image/mission_check.svg"
          v-if="checkAnswer(answer, index)"
        />
        <img src="@/assets/image/mission_uncheck.svg" v-else />
        <span style="margin-left: 8px; font-size: 12px;">{{ answer.answer }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MissionPR",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  /*** data ***/
  data() {
    return {};
  },

  /*** created ***/
  created() {},

  /*** computed ***/
  computed: {},

  /*** watch ***/
  watch: {},

  /*** methods ***/
  methods: {
    checkAnswer(answer, index) {
      if (!this.data.dataList[index]) {
        return false;
      }
      // return answer.answerType === this.data.dataList[index].data
      return this.data.dataList[index].data === 'true'
    },
  },
};
</script>

<style scoped lang="scss">
.flex-align-center {
  display: flex;
  align-items: center;
}
</style>
