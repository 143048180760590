import { getFindNestedObj, getParents } from "@/modules/util";

export default {
  G_NAV_LOGIN: (state) => {
    return state.ALL.filter((nav) => nav.id === "0");
  },
  G_NAV_ALL: (state) => {
    return state.ALL.filter((nav) => nav.id !== "0")
      .filter((nav) => nav.id !== "1")
  },
  G_NAV_SEL: (state) => {
    return state.ALL && state.ID ? getFindNestedObj(state.ALL, state.ID) : null;
  },
  G_NAV_ID: (state) => state.ID,
  G_NAV_CHECK_LOGIN: (state) => {
    return state.ID ? state.ID.substr(0, 1) === "0" : false;
  },
  G_NAV_PARENTS: (state) => {
    if (state.ALL && state.ID) {
      const parents = [];
      const ids = state.ID.split("-");
      for (let i = 0; i < ids.length; ++i) {
        parents.push(getParents(state.ALL, state.ID, i));
      }
      return parents;
    } else return null;
  },
  G_NAV_SIBLINGS: (state, { G_NAV_PARENTS }) => {
    if (G_NAV_PARENTS) {
      let len = G_NAV_PARENTS.length > 1 ? 1 : G_NAV_PARENTS.length;
      if (G_NAV_PARENTS[len].sub) {
        return G_NAV_PARENTS[len].sub;
      }else{
        return []
      }
    } else return [];
    // return G_NAV_PARENTS  ? G_NAV_PARENTS[G_NAV_PARENTS.length - 2].sub : []
  },
  G_MAIN_TI: (state, { G_NAV_PARENTS }) =>
    G_NAV_PARENTS ? G_NAV_PARENTS[1].title : "",
  G_NAV_SHOW: (state) => state.SHOW,
  G_NAV_LAST_PATH: (state) => state.LAST_PATH,
};
