<template>
  <div class="w-100">
    <div class="commission-header-wrap flex-space-between flex-align-center">
      <span>자동알림 내용 수정</span>
      <img
        src="@/assets/image/close.svg"
        style="cursor: pointer"
        @click="closeModal"
      />
    </div>
    <div class="w-100" style="padding: 20px">
      <div class="commission-content-wrap">
        <span class="commission-category">카테고리</span>
        <span>{{ category }}</span>
      </div>
      <div class="commission-content-wrap">
        <span class="commission-category">조건</span>
        <select-box
          :opt-nm="conditionList"
          :size="SIZE_M"
          v-model="params.conditionEsntlId"
        />
      </div>
      <div class="commission-content-wrap" style="align-items: flex-start">
        <span class="commission-category">알림 내용</span>
        <text-box wp-cls="w-100" v-model="params.content" />
      </div>
      <div class="commission-content-wrap" v-if="isMember">
        <span class="commission-category">연결 페이지</span>
        <select-box
          :opt-nm="urlList"
          :size="SIZE_M"
          style="width: 300px"
          v-model="params.urlEsntlId"
        />
      </div>
      <div class="commission-content-wrap" v-else>
        <span class="commission-category">연결 URL</span>
        <input-text v-model="params.url" />
      </div>
    </div>
    <div class="commission-bottom-wrap">
      <button-common :size="SIZE_T" :clr="CLR_M" @click="editAlarm"
        >수정</button-common
      >
      <button-common
        :size="SIZE_T"
        :clr="CLR_W"
        wp-cls="mr-8"
        @click="closeModal"
        >취소</button-common
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalAlarm",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      isMember: false,
      conditionList: [],
      urlList: [],
      category: "",
      params: {
        esntlId: "",
        content: "",
        conditionEsntlId: "",
        url: "",
        urlEsntlId: "",
      },
    };
  },

  /*** created ***/
  async mounted() {},

  /*** computed ***/
  computed: {
    ...mapGetters(["G_MODAL_TOGGLE", "G_ALARM_DATA", "G_IS_MEMBER"]),
  },

  /*** watch ***/
  watch: {
    async G_MODAL_TOGGLE(val) {
      // 열림
      if (val) {
        this.isMember = this["G_IS_MEMBER"];
        await this.getCondition();
        if (this.isMember) {
          await this.getUrl();
        }
        this.setData();
      }
    },
  },

  /*** methods ***/
  methods: {
    async getUrl() {
      const rs = await this.$axios({
        url: "/admin/alarm/member/url",
        params: {},
      });
      if (rs.result === "SUC") {
        this.urlList = rs.resultList;
        this.urlList = this.urlList.map((item) => {
          return { title: item.title, value: item.esntlId };
        });
      }
    },
    async getCondition() {
      let url = this.isMember
        ? "/admin/alarm/member/condition"
        : "/admin/alarm/partner/condition";
      const rs = await this.$axios({
        url: url,
        params: {},
      });
      if (rs.result === "SUC") {
        this.conditionList = rs.resultList;
        this.conditionList = this.conditionList.map((item) => {
          return { title: item.title, value: item.esntlId };
        });
      }
    },
    setData() {
      const data = this["G_ALARM_DATA"];
      this.params.esntlId = data.esntlId ? data.esntlId : "";
      this.category = data.category ? data.category : "";
      this.params.content = data.content ? data.content : "";
      this.params.conditionEsntlId = data.conditionEsntlId
        ? data.conditionEsntlId
        : "";
      this.params.url = data.url ? data.url : "";
      this.params.urlEsntlId = data.urlEsntlId ? data.urlEsntlId : "";
    },
    async editAlarm() {
      const data = JSON.parse(JSON.stringify(this.params));
      let url = this.isMember
        ? "/admin/alarm/member/update"
        : "/admin/alarm/partner/update";
      if (this.isMember) {
        delete data.url;
      } else {
        delete data.urlEsntlId;
      }
      const rs = await this.$axios({
        url: url,
        params: data,
      });
      if (rs.result === "SUC") {
        this.closeModal();
      } else {
        //console.log("알람 설정 실패");
      }
    },
    closeModal() {
      this.$store.dispatch("A_MODAL_TOGGLE", false);
    },
  },
};
</script>

<style scoped lang="scss">
.commission-header-wrap {
  background: #f8f8fb;
  padding: 16px;
}
.commission-content-wrap {
  padding: 16px;
  display: flex;
  align-items: center;
}
.commission-category {
  color: #828699;
  font-weight: 500;
  min-width: 150px;
}
.commission-bottom-wrap {
  background: #f8f8fb;
  padding: 16px;
  display: flex;
  flex-direction: row-reverse;
}
</style>
