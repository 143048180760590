<template>
  <div class="w-100" style="padding: 32px 0 24px 0">
    <p clsss="duplicate-title" style="text-align: center">본인 인증 실패</p>
    <div class="duplicate-content" style="margin-top: 10px" v-html="content" />
    <div class="flex-justify-center" style="margin-top: 20px">
      <button-common :size="SIZE_T" :clr="CLR_W" @click="closeModal"
        >닫기</button-common
      >
    </div>
    <p class="duplicate-link" style="margin-top: 20px" @click="clickChannel">
      고객센터 문의
    </p>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ModalError",

  /*** mixins ***/
  mixins: [],

  /*** inject ***/
  inject: [],

  /*** components ***/
  components: {},

  /*** props ***/
  props: {},

  /*** data ***/
  data() {
    return {
      content: "",
    };
  },

  /*** created ***/
  async mounted() {},

  /*** computed ***/
  computed: {
    ...mapGetters(["G_ERROR_CODE"]),
  },

  /*** watch ***/
  /*** watch ***/
  watch: {
    G_ERROR_CODE() {
      switch (this["G_ERROR_CODE"]) {
        case 201:
          this.content = "금융기관 시스템 정기점검 시간입니다.";
          break;
        case 301:
          this.content =
            "금융계좌에 등록된 생년월일이 일치하지 않습니다.<br/>생년월일을 올바르게 입력해주세요.";
          break;
        case 202:
        case 203:
        case 300:
          this.content =
            "계좌번호가 유효하지 않습니다.<br/>계좌번호를 올바르게 입력해주세요.";
          break;
        case 204:
        case 302:
        case 400:
        case 401:
          this.content =
            "거래가 불가능한 금융계좌 입니다.<br/>금융계좌와 등록정보를 다시한번 확인후 입력해주세요.";
          break;
        default:
          this.content =
            "금융계좌 확인 오류입니다.<br/>고객센터에 문의해주세요.";
          break;
      }
    },
  },

  /*** methods ***/
  methods: {
    closeModal() {
      this.$store.dispatch("A_ERROR_CODE", 0);
      this.$store.dispatch("A_MODAL_TOGGLE", false);
    },
    clickChannel() {
      window.open("http://pf.kakao.com/_pxkxnQb/chat");
    },
  },
};
</script>

<style scoped lang="scss">
.duplicate-title {
  font-size: 16px;
  color: #12131a;
}
.duplicate-content {
  font-size: 12px;
  font-weight: 500;
  color: #828699;
  text-align: center;
}
.duplicate-link {
  font-size: 12px;
  font-weight: 500;
  color: #515466;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
}
</style>
