<template>
  <div class="input-rel">
    <textarea :value="value" :class="cls" :placeholder="holderNm" :maxlength="limitNm || 524288" @input="handleInput" ref="inputRef"/>
    <div :class="cls2" v-if="useCounter">
      <span :class="counterCls">{{ value.length }}</span>
      <span>/</span>
      <span>{{ limitNm }}</span>
    </div>
  </div>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'TextUpload',
  mixins: [mixinForm],

  /*** props ***/
  props: {
    value: {
      type: String,
      default: '',
    },
  },

  /*** data ***/
  data() {
    return {
      counterCls: '',
    }
  },

  /*** computed ***/
  computed: {
    cls() {
      let cls = ''
      cls += 'upload-img-box-input '
      cls += this.wpCls || ''
      return cls
    },
    cls2() {
      let cls = ''
      cls += 'input-count fr-12 f-gray '
      cls += this.wpCls2 || ''
      return cls
    },
  },

  /*** watch ***/
  watch: {
    value() {
      if(this.useCounter) {
        this.counterCls = this.value.length >= this.limitNm ? 'f-red' : 'f-main'
      }
      if(this.value.length === 0) this.$refs.inputRef.focus()
    }
  },

  /*** methods ***/
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value)
    },
  }
}
</script>

<style scoped class="scss">

</style>
