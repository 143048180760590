<template>
  <div class="current-bar mb-16" v-if="G_MAIN_TI">
    <span class="fb-16">{{ G_MAIN_TI }}</span>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'MainTitle',
  data() {
    return {

    }
  },
  computed: {
    ...mapGetters(['G_MAIN_TI', 'G_NAV_SEL', 'G_NAV_SIBLINGS', 'G_NAV_ALL', 'G_NAV_PARENTS']),
  }
}
</script>

<style scoped lang="scss">
.current-bar {
  border: 1px solid var(--gray20);
  background: var(--white);
  margin: 0 auto;
}
</style>
