<template>
  <div :class="cls">
    <input type="text" :value="value" :class="cls2" :placeholder="holderNm" ref="inputRef" @input="handleInput" @blur="handlerBlur"/>
    <div :class="cls3">자</div>
  </div>
</template>

<script>
import mixinForm from '@/mixins/mixinForm'

export default {
  name: 'InputText',
  mixins: [mixinForm],

  /*** props ***/
  props: {
    value: {
      type: String,
      default: '',
    },
  },

  /*** data ***/
  data() {
    return {
      ntDt: null,
    }
  },

  /*** created ***/
  created() {
    this.ntDt = this._.cloneDeep(this.$data)
  },

  /*** computed ***/
  computed: {
    cls() {
      let cls = ''
      cls += 'd-flex align-items-center '
      cls += this.wpCls || ''
      return cls
    },
    cls2() {
      let cls = ''
      cls += 'input-title '
      cls += this.wpCls2 || ''
      return cls
    },
    cls3() {
      let cls = ''
      cls += 'input-side small '
      cls += this.wpCls3 || ''
      return cls
    },
  },

  /*** watch ***/
  watch: {
    value() {
      if (this.value && this.value.length === 0) this.$refs.inputRef.focus()
    }
  },

  /*** methods ***/
  methods: {
    isValidate(e) {
      // const char = String.fromCharCode(e.target.value)
      if(this._.inRange(e.target.value, 1, 1000)) return true
      else {
        e.target.value = '1'
        e.preventDefault()
      }
    },
    handleInput(e) {
      const number = Number(e.target.value.trim())
      const isNumber = !isNaN(number)
      //console.log(isNumber)
      if(isNumber && number > 0 && this._.inRange(number, 1, 1001)) {
        this.$emit('input', String(number))
      }
      else {
        this.$emit('input', '')
        this.$forceUpdate()
        return false
      }
    },
    handlerBlur() {
      this.$emit('blur')
    }
  }
}
</script>

<style scoped lang="scss">
input[type="text"] {
  &.active.input-title {
    border-color: var(--sun);
  }
}
</style>
