import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { makeRoutes } from '@/modules/util'
import nav from '@/assets/json/nav.js'

Vue.use(VueRouter)

store.dispatch('A_NAV_ALL', nav)

const routes = makeRoutes(store.state.NAV.ALL, [])
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
var prevId = "";
var popstateCheck = false;
// //console.log(process.env)
// //console.log(store.state.NAV.ALL)
// //console.log(routes)
// 라우터 가드 등록



router.beforeEach((to, from, next) => {
  let id = to.meta.id;
  let fromPath = store.state.NAV.LAST_PATH;
  let auth = localStorage.getItem("auth");
  let adminAuth = to.meta.auth;
  let adminAuthList = auth === "ADM" ? localStorage.getItem("authList").split(",") : "";
  //console.log("id",id)
  if (popstateCheck) {
    popstateCheck = false;
    
    if (prevId.charAt(0) === "2") {
      
      return fromPath;
    }
    
  }
  prevId = id;
    
  if (id.substr(0, 1) === "0") {
    next()
  } else if (!auth) {
    next(fromPath)
  } else if (auth === 'COM') {
    next()
  } else {
    if(adminAuthList.includes(adminAuth)) {
      next()
    } else {
      next(fromPath)
    }
  }
});

router.afterEach((to) => {

  let id = to.meta.id;
  //console.log(id)
  store.dispatch("A_NAV_SHOW", true);
  store.dispatch("A_NAV_ID", id);
  store.dispatch("A_NAV_LAST_PATH", to.fullPath);
  if (id.substr(0, 1) !== "0" && localStorage.getItem("auth") === "COM") {
    store.dispatch("A_BELL_COUNT", {});
  } else {
    store.dispatch("A_SHOW_BELL", false);
  }
});
router.onError((err) => {
  console.error(err);
  
});

export default router

// 뒤로가기 버튼 이벤트 핸들링
window.addEventListener('popstate', () => {
  popstateCheck = true;
  //console.log("뒤로가기 버튼 누름",window.location.pathname)
});
