<template>
  <li class="menu1">
    <span class="fr-14 f-white" @click="onSubToggle">{{ menu.title }}</span>
    <transition name="menu-smooth" mode="out-in">
      <ul :class="`sub ${show ? 'active' : ''}`" v-show="show">
        <template v-for="v in menu.sub">
          <nav-sub :key="v.id" :subMenu="v" v-if="!v.isNotShow"/>
        </template>
      </ul>
    </transition>
  </li>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import NavSub from '@/components/common/aside-wrapper/NavSub'

export default {
  name: 'NavMain',
  components: { NavSub },
  props: ['menu'],
  data() {
    return {
      show: false,
    }
  },
  created() {
    this.chkShow()
  },
  computed: {
    ...mapGetters(['G_NAV_ID', 'G_NAV_PARENTS']),
  },
  watch: {
    G_NAV_ID: function () {
      this.chkShow()
    }
  },
  methods: {
    onSubToggle() {
      this.show = !this.show
    },
    chkShow() {
      this.show = _.some(this.G_NAV_PARENTS, { id: this.menu.id })
    }
  }
}
</script>

<style scoped lang="scss">
.active {
  &::before {
    transform: rotate(180deg);
  }
}
</style>
