<template>
  <div class="d-flex space-between align-items-center">
    <div class="path fm-16 f-main">
      Home
      <span class="fr-14" v-for="folder in G_FOLDER_ARR" :key="folder.id"> &gt; {{ folder.name }}</span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'PathLocation',

  /*** computed ***/
  computed: {
    ...mapGetters(['G_FOLDER', 'G_FOLDER_ID', 'G_FOLDER_ARR'])
  },
}
</script>

<style scoped lang="scss">

</style>
