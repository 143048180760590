<template>
  <svg :width="width" :height="height" :viewBox="viewBox" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 6L8 10.0001L12 14" stroke="#3A3D4D" stroke-linecap="round"></path>
    <path d="M20 0L20.8 0V-0.8L20 -0.8V0ZM20 20V20.8H20.8V20H20ZM0 20H-0.799999V20.8H0V20ZM0 0V-0.8L-0.799999 -0.8V0L0 0ZM19.2 0L19.2 20H20.8L20.8 0L19.2 0ZM20 19.2L0 19.2V20.8L20 20.8V19.2ZM0.799999 20L0.799999 0L-0.799999 0L-0.799999 20H0.799999ZM0 0.8L20 0.8V-0.8L0 -0.8V0.8Z" fill="#3A3D4D"></path>
  </svg>
</template>

<script>
export default {
  name: 'SvgArrowLt',

  /*** props ***/
  props: {
    width: {
      type: Number,
      default: 20
    },
    height: {
      type: Number,
      default: 20
    },
    viewBox: {
      type: String,
      default: '0 0 20 20'
    },
    fill: {
      type: String,
      default: 'none'
    },
  },
}
</script>

<style scoped>

</style>
